import React, { useState, useEffect } from 'react';

const AnimatedText = ({ texts, interval = 5000 }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [fade, setFade] = useState(false);

  useEffect(() => {
    const fadeTimer = setInterval(() => {
      setFade(true);
      setTimeout(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % texts.length);
        setFade(false);
      }, 500); // Half a second for fade out
    }, interval);

    return () => clearInterval(fadeTimer);
  }, [texts, interval]);

  return (
    <span className={`transition-opacity duration-500 ${fade ? 'opacity-0' : 'opacity-100'}`}>
      {texts[currentIndex]}
    </span>
  );
};





  export default AnimatedText;