import React, { useState, useEffect, useRef, useMemo } from 'react';
import { ReactComponent as VectorMap } from './../assets/Vector.svg';
import InvestorWhite from './investorWhite';
import Slider from 'react-slick';
import { globallist, globalorderlist, investors, kolslist } from './../components/data';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import KolsCard from './kolCard';
import { Earth, LayoutGrid } from 'lucide-react';

const CustomSwitch = ({ checked, onChange }) => (
  <label className="flex items-center cursor-pointer w-full justify-center">
    <div className='space-x-4  flex items-center'>
    <Earth className='w-5 h-5' />
    <div className="relative">
      <input type="checkbox" className="sr-only" checked={checked} onChange={onChange} />
      <div className="w-6 h-2 bg-white/20 rounded-full shadow-inner"></div>
      <div className={`absolute w-4 h-4 bg-white rounded-full shadow -left-1 -top-1 transition ${checked ? 'transform translate-x-full bg-yellow-100' : 'bg-blue-300'}`}></div>
    </div>
    <LayoutGrid className='w-5 h-5' />
    </div>
    <div className="ml-5 text-white/80 font-medium flex justify-center text-center">
      {checked ? 'Global KOLs' : 'Regional KOLs' }
    </div>
  </label>
);

const WorldMap = () => {
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [showGlobalList, setShowGlobalList] = useState(true);
  const mapRef = useRef(null);

  const filteredInvestors = useMemo(() => {
    if (showGlobalList) return investors; // Show all investors for global list
    if (!selectedLocation) return [];
    return investors.filter(investor => investor.country === selectedLocation.name);
  }, [selectedLocation, investors, showGlobalList]);

  const locations = [
    { name: 'Ukraine', x: 650, y: 95 },
    { name: 'Turkey', x: 670, y: 140 },
    { name: 'India', x: 860, y: 220 },
    { name: 'Vietnam', x: 1005, y: 240 },
    { name: 'South America', x: 220, y: 340 }
  ];

  const handlePinClick = (location) => {
    setSelectedLocation(location);
  };

  const handleOutsideClick = (event) => {
    if (mapRef.current && !mapRef.current.contains(event.target)) {
      setSelectedLocation(null);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    autoplay: true,
    slidesToScroll: 1,
    adaptiveHeight: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        }
      }
    ]
  };

  const [showMore, setShowMore] = useState(false);

  const handleShowMore = () => {
    setShowMore(!showMore);
  };


  const handleToggleGlobalList = () => {
    setShowGlobalList(!showGlobalList);
    setSelectedLocation(null); // Reset selected location when toggling
  };

  const renderInvestorList = (list, isGlobal = false) => (
    <div className={isGlobal ? "w-full grid grid-cols-3 md:grid-cols-3 lg:grid-cols-6 gap-1 mt-8" : "w-full"}>
      {filteredInvestors.length > 0 ? (
        isGlobal ? (
          list.map((investor, index) => (
            <div key={index}>
              <KolsCard {...investor} />
            </div>
          ))
        ) : (
          <Slider {...settings}>
            {filteredInvestors.map((investor, index) => (
              <div key={index} className="px-2">
                <InvestorWhite {...investor} />
              </div>
            ))}
          </Slider>
        )
      ) : (
        <p>No investors found for this location.</p>
      )}
    </div>
  );

  return (
    <div className="relative md:max-w-7xl w-full h-full">
      <div className="relative top-4 left-4 z-20">
        <CustomSwitch checked={showGlobalList} onChange={handleToggleGlobalList} />
      </div>
      
      {showGlobalList ? (
    <div className="w-full h-full flex flex-col items-center justify-start my-5 p-4 pb-20 overflow-auto">
    {renderInvestorList(globalorderlist, true)}
    <div 
      className="mt-4 px-4 py-2 border border-2 border-white/20 text-white rounded-full text-sm">
        And 500+ more
    </div>
  </div>
    ) : (
        <>
          <TransformWrapper
            initialScale={1}
            initialPositionX={0}
            initialPositionY={0}
          >
            {({ zoomIn, zoomOut, resetTransform }) => (
              <React.Fragment>
                <div className="tools absolute top-16 left-4 z-10 block md:hidden">
                  <button onClick={() => zoomIn()} className="bg-blue-500 text-white px-2 rounded mr-2">+</button>
                  <button onClick={() => zoomOut()} className="bg-blue-500 text-white px-2 rounded mr-2">-</button>
                  <button onClick={() => resetTransform()} className="bg-blue-500 text-white px-2 rounded">Reset</button>
                </div>
                <TransformComponent wrapperClass="!w-full !h-full" contentClass="!w-full !h-full">
                  <svg viewBox="0 0 1350 600" className="w-full h-full">
                    <VectorMap />
                    {locations.map((location) => (
                      <g key={location.name} onClick={() => handlePinClick(location)}>
                        <circle
                          cx={location.x}
                          cy={location.y}
                          r="6"
                          fill="blue"
                          className={`cursor-pointer hover:fill-blue-800 transition-opacity duration-300 ${
                            selectedLocation && selectedLocation.name !== location.name ? 'opacity-20' : 'opacity-100'
                          }`}
                        />
                        {selectedLocation && selectedLocation.name === location.name && (
                          <circle
                            cx={location.x}
                            cy={location.y}
                            r="20"
                            fill="blue"
                            className="opacity-15"
                          />
                        )}
                        <text
                          x={location.x + 15}
                          y={location.y + 5}
                          fill="#ffffff"
                          fontSize="12"
                          className="pointer-events-none font-bold"
                        >
                          {location.name}
                        </text>
                      </g>
                    ))}
                  </svg>
                </TransformComponent>
              </React.Fragment>
            )}
          </TransformWrapper>

          {/* Regional Investor display */}
          {selectedLocation && (
            <div className="popup absolute top-4 right-4 w-full md:w-1/2 p-4" ref={mapRef}>
              <div className='flex text-4xl mb-5 justify-end items-end w-full'>
                {selectedLocation.name}
              </div>
              {renderInvestorList()}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default WorldMap;