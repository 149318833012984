import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/home";
import Privacy from "./pages/privacy";
import Terms from "./pages/terms";
import Investors from "./pages/kols";
import Join from "./pages/join";
import Media from "./pages/media";
import Portfolio from "./pages/portfolio";
import Testimonials from "./pages/testimonials";
import Stories from "./pages/stories";
import ScrollToTop from "./components/ScrollToTop";


function App() {
  return (
    <div>
      <Router>
      <ScrollToTop />
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/media" element={<Media />} />
          <Route path="/join" element={<Join />} />
          <Route path="/kols" element={<Investors />} />
          <Route path="/portfolio" element={<Portfolio />} />
          <Route path="/testimonials" element={<Testimonials />} />
          <Route path="/stories" element={<Stories />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
