import { useState } from 'react';
import WorldMap from '../components/WorldMap';
import { Link } from 'react-router-dom';
import Footer from '../components/footer';
import symbolLogo from './../assets/logo.png';
import { stories, testimonials } from '../components/data';

function Stories() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [expandedStoryIndex, setExpandedStoryIndex] = useState(null);

  const toggleStoryExpansion = (index) => {
    setExpandedStoryIndex(prevIndex => prevIndex === index ? null : index);
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const scrollToContact = () => {
    const anchor = document.querySelector('#contactus');
    anchor?.scrollIntoView({ behavior: 'smooth', block: 'center' });
  };

  return (
    <>
    <div className="backgroundColors pb-10">
    {/*<div className='Overlay-bg right-[0%] opacity-80'></div>*/}
    <div className="  text-white min-h-screen">
      <header className="flex justify-center items-center mb-2 w-full">
      <nav className="w-full z-50 m-2">
      <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-2">
        <Link to="/" className="flex items-center space-x-3 rtl:space-x-reverse">
        <img src={symbolLogo} class="h-12" alt="KOL Capital Logo" />
        <span className="self-center text-sm font-semibold whitespace-nowrap dark:text-white">KOL Capital</span>
        </Link>
        <div className="flex md:order-2 space-x-3 md:space-x-0 rtl:space-x-reverse items-center">
        <button onClick={scrollToContact} type="button" className="hidden md:block text-black bg-white hover:text-white hover:bg-blue-600 focus:outline-none font-medium rounded-full text-xs px-4 py-2 text-center ">
              Contact Us
            </button>
          <button
            onClick={toggleMenu}
            type="button"
            className="inline-flex items-center p-2 w-8 h-8 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none "
            aria-controls="navbar-sticky"
            aria-expanded={isMenuOpen}
          >
            <span className="sr-only">Open main menu</span>
            <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
              <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 1h15M1 7h15M1 13h15" />
            </svg>
          </button>
        </div>
        <div className={`items-center justify-between w-full md:flex md:w-auto md:order-1 ${isMenuOpen ? 'block bg-black' : 'hidden '}`} id="navbar-sticky">
          <ul className="flex flex-col text-xs p-4 md:p-0 mt-4 font-medium rounded-lg md:space-x-8 rtl:space-x-reverse md:flex-row md:mt-0 md:border-0">
            <li>
                <Link to="/" class="block py-2 px-3 text-white rounded opacity-50 hover:bg-gray-600 md:hover:bg-transparent md:hover:opacity-100 md:p-0 md:dark:hover:text-blue-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700">Home</Link>
            </li>
            <li>
                <Link to="/portfolio" class="block py-2 px-3 text-white opacity-50 rounded hover:bg-gray-600 md:hover:bg-transparent md:hover:opacity-100 md:p-0 md:dark:hover:text-blue-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700">Portfolio</Link>
            </li>
            <li>
            <Link to="/kols" className="block py-2 px-3 text-white opacity-50 rounded hover:bg-gray-600 md:hover:bg-transparent md:hover:opacity-100 md:p-0 md:dark:hover:text-blue-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700">Networks</Link>
            </li>
            <li>
              <Link to="/#team" className="block cursor-pointer py-2 px-3 text-white opacity-50 rounded hover:bg-gray-600 md:hover:bg-transparent md:hover:opacity-100 md:p-0 md:dark:hover:text-blue-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700">Team</Link>
            </li>
            <li>
                <Link to="#" class="block py-2 px-3 text-white bg-blue-700 rounded md:bg-transparent md:p-0 md:dark:text-blue-500" aria-current="page">Case Studies</Link>
            </li>
            <li>
                <Link to="/testimonials" class="block py-2 px-3 text-white rounded opacity-50 hover:bg-gray-600 md:hover:bg-transparent md:hover:opacity-100 md:p-0 md:dark:hover:text-blue-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700">Testimonials</Link>
            </li>
            <button onClick={scrollToContact} type="button" className="block md:hidden text-black bg-white hover:text-white hover:bg-blue-600 focus:outline-none font-medium rounded-full text-xs px-4 py-2 text-center ">
              Contact Us
            </button>
            </ul>
        </div>
        </div>
        </nav>
      </header>
      <div className='max-w-7xl grid grid-cols-1 gap-8 mx-auto mt-10 text-center'>
        <h2 className="text-4xl">Case Studies</h2>
        <p className="mb-6 text-sm text-gray-400">
          Explore our impactful work through detailed case studies of some of our standout investments:
        </p>
      </div>
      <div className="max-w-5xl mx-auto mb-10 grid grid-cols-1 md:grid-cols-1">
        {stories.map((story, index) => (
          <div 
            key={index} 
            className={`relative z-30 rounded-3xl p-4 bg-white/5 hover:bg-gradient-to-tr hover:from-white/10 hover:to-transparent flex flex-col transform transition-colors items-start bg-white/5 m-4 cursor-pointer ${
              index === stories.length - 1 && stories.length % 2 !== 0 ? 'md:col-span-1' : ''
            }`}
            onClick={() => toggleStoryExpansion(index)}
          >
            <div className="flex flex-col md:flex-row items-center w-full gap-5">
              <div className={`rounded-2xl p-3 mb-4 self-start animate-fade-in basis-2/4`}>
                <img src={story.logo} alt={`${story.title} logo`} className="w-auto" />
              </div>
              <div className='basis-2/4'>
                <h3 className="text-xl font-semibold mb-2">{story.title}</h3>
                <p className="text-sm mb-4 opacity-50">{story.subtitle}</p>
              </div>
            </div>
            {expandedStoryIndex === index && (
              <div className="mt-4 text-sm opacity-80 transition-all duration-300 ease-in-out space-y-2 p-6">
                <p>{story.description}</p>
                <p>{story.description2}</p>
              </div>
            )}
          </div>
        ))}
      </div>
      </div>
      </div>
      <div id="contactus">
    <Footer /> 
    </div>


    </>
  );
};

export default Stories;
