import { Twitter, Instagram, Youtube, Send, MessageCircle, Globe } from 'lucide-react';
import bgCardPortfolio from './../assets/Portfolio3.png';
import bgWhitePortfolio from './../assets/Portfolio2.png';
import { Link } from 'react-router-dom';

const PortfolioCard = ({ logo, name, category, website, bgColor }) => (
  <div className="relative pb-8 group hover:shadow-2xl z-50 cursor-pointer">
    <Link to={website} target="_blank" rel="noopener noreferrer" >
    <div className={`z-30 relative rounded-3xl flex flex-col transform transition-colors`}>
      <div className={`rounded-2xl ${logo.bgColor} p-3 mb-2 self-start animate-fade-in`}>
        <img src={logo.src} alt={`${name} logo`} className="w-auto" />
      </div>
      <div className="mt-auto px-5">
        <div className="flex justify-center items-center mt-2">
          <h3 className="font-semibold text-sm md:text-xl align-center">{name}</h3>
        </div>
      </div>
    </div>
    <div className="absolute z-10 inset-0">
      <img
        src={bgCardPortfolio}
        alt="Crypto landscape illustration"
        className="w-full h-full object-fit transition-opacity opacity-0 duration-300 group-hover:opacity-0"
      />
    </div>
    <div className="absolute z-20 inset-0">
      <img
        src={bgWhitePortfolio}
        alt="Crypto landscape illustration"
        className="w-full h-full object-fit transition-opacity opacity-0 duration-300 group-hover:opacity-100"
      />
    </div>
    </Link>
  </div>
);

  export default PortfolioCard;
