import { Link, useLocation } from 'react-router-dom';
import { Twitter, Instagram, Youtube, Disc, Send } from 'lucide-react';
import bgCardInvestor from './../assets/KolsBg.png';

const KolsCard = ({ name, title, imageSrc, socialLinks }) => {
  const location = useLocation();
  const hideFollowers = location.pathname === '/kols';

  // Select the primary social link (first one for this example)
  const primaryLink = socialLinks.length > 0 ? socialLinks[0].url : '#';

  return (
    <div className="relative group hover:shadow-2xl">
    <Link to={primaryLink} target="_blank" rel="noopener noreferrer" >
      <div className={`z-20 relative rounded-3xl overflow-hidden ${imageSrc ? '' : ''} p-4 transform transition-colors text-gray-600 hover:text-white`}>
        {imageSrc ? (
          <img src={imageSrc} alt={name} className="w-full h-auto object-cover rounded-2xl mb-4 animate-fade-in" />
        ) : (
          <div className="w-full mb-4"></div>
        )}
        <div className='flex justify-center md:px-5 items-center'>
          <h3 className="text-sm font-bold">{name}</h3>
          {/*
          <div className="flex space-x-3 items-center">
            {socialLinks.map((social, index) => (
              <Link key={index} to={primaryLink} target="_blank" rel="noopener noreferrer" className="hover:text-blue-500" onClick={(e) => e.stopPropagation()}>
                {social.platform === 'twitter' && '𝕏'}
                {social.platform === 'instagram' && <Instagram size={14} />}
                {social.platform === 'youtube' && <Youtube size={14} />}
                {social.platform === 'discord' && <Disc size={14} />}
                {social.platform === 'telegram' && <Send size={14} />}
                {social.platform === 'tiktok' && <Send size={14} />}
              </Link>
            ))}
          </div>
          */}
        </div>
        {!hideFollowers && title && <p className="px-5 text-xs mb-3 flex justify-center">{title} Followers</p>}
      </div>
      <div className="absolute z-10 inset-0 overflow-hidden">
        <img
          src={bgCardInvestor}
          alt="Crypto landscape illustration"
          className="w-full h-full object-fit opacity-0 transition-opacity duration-300 group-hover:opacity-100"
        />
      </div>
    </Link>
    </div>
  );
};

export default KolsCard;
