import { Twitter, Instagram, Youtube, Disc, Send } from 'lucide-react';
import bgCardInvestor from './../assets/InvestorBg.png';


const InvestorWhite = ({ name, title, imageSrc, socialLinks }) => (
  <div className="relative group hover:shadow-2xl">
    <div className={`z-20 relative rounded-3xl overflow-hidden ${imageSrc ? '' : ''} p-4 transform transition-colors text-gray-600 text-black`}>
      {imageSrc ? (
        <img src={imageSrc} alt={name} className="w-full h-auto object-cover rounded-2xl mb-4 animate-fade-in" />
      ) : (
        <div className="w-full h-48 bg-[#050916] rounded-2xl mb-4"></div>
      )}
      <div className='flex justify-between px-2 text-white'>
        <h3 className="text-sm font-bold mb-1">{name}</h3>
        <div className="flex space-x-3 items-center">
          {socialLinks.map((social, index) => (
            <a key={index} href={social.url} target="_blank" rel="noopener noreferrer" className="hover:text-blue-500">
              {social.platform === 'twitter' && <Twitter size={14} />}
              {social.platform === 'instagram' && <Instagram size={14} />}
              {social.platform === 'youtube' && <Youtube size={14} />}
              {social.platform === 'discord' && <Disc size={14} />}
              {social.platform === 'telegram' && <Send size={14} />}
              {social.platform === 'tiktok' && <Send size={14} />}
            </a>
          ))}
        </div>
      </div>
      {title && <p className="px-5 text-xs mb-3 text-white/60">{title}</p>}
    </div>
    <div className="absolute z-10 inset-0 overflow-hidden">
      <img
        src={bgCardInvestor}
        alt="Crypto landscape illustration"
        className="w-full h-full object-fit opacity-100 transition-opacity duration-300 group-hover:opacity-100"
      />
    </div>
  </div>
  );
  
  export default InvestorWhite;
