import { Twitter, Instagram, Youtube, Disc, Send, ArrowRight, MessageCircle, Globe, Star, Target, Users } from 'lucide-react';
import bgFooter from './../assets/BgFooter.png';
import { Link } from 'react-router-dom';
import ContactForm from './ContantForm';
import symbolLogo from './../assets/logo.png';

const FooterLink = ({ href, children }) => (
    <a href={href} className="text-gray-400 hover:text-blue-500 transition-colors">
      {children}
    </a>
  );
  
  const SocialIcon = ({ Icon, href }) => (
    <a href={href} className="text-gray-400 hover:text-blue-500 transition-colors">
      <Icon size={20} />
    </a>
  );
  
const Footer = () => (

<footer className="text-white w-full h-auto relative overflow-hidden bg-black pt-10 rounded-2xl">
  <div className="max-w-7xl mx-auto relative z-10 flex flex-col justify-center h-full py-5 w-full">
    <div className="flex gap-8 mb-20 justify-center mx-auto md:mx-0">
      <ContactForm />
    </div>
    <div className=" flex flex-col items-center justify-left">
      <Link to="/" className="flex items-center space-x-3 rtl:space-x-reverse mb-2">
        <img src={symbolLogo} className="h-12" alt="KOL Capital Logo" />
        <span className="self-center text-lg font-semibold whitespace-nowrap dark:text-white">KOL Capital</span>
      </Link>
      <p className="text-gray-400 text-sm mb-4">Shaping the Future of Crypto</p>
      <div className="flex text-sm space-x-4 mb-4">
        <SocialIcon Icon={Twitter} href="https://www.x.com/kolcapital" />
        <SocialIcon Icon={Instagram} href="https://www.instagram.com/kolcapital" />
      </div>
    </div>
  </div>
  <p className="text-sm opacity-40 flex w-full justify-center pb-4">© 2024 KOL Capital. All rights reserved.</p>
</footer>
  );

export default Footer;
