import React, { useState, useEffect, useRef } from 'react';
import * as THREE from 'three';
import { TrendingUp, Shield, Globe, Zap } from 'lucide-react';
import { motion, AnimatePresence, useAnimation } from 'framer-motion';
import { Check, X } from 'lucide-react';
import { ChevronLeft, ChevronRight, Star } from 'lucide-react';

const TestimonialCard = ({ name, role, company, content, rating }) => (
  <motion.div
    className="bg-white bg-opacity-10 backdrop-filter backdrop-blur-lg rounded-xl p-8 shadow-lg"
    initial={{ opacity: 0, scale: 0.9 }}
    animate={{ opacity: 1, scale: 1 }}
    exit={{ opacity: 0, scale: 0.9 }}
    transition={{ duration: 0.5 }}
  >
    <div className="flex items-center mb-4">
      {[...Array(5)].map((_, i) => (
        <Star
          key={i}
          size={20}
          className={i < rating ? 'text-yellow-400 fill-current' : 'text-gray-400'}
        />
      ))}
    </div>
    <p className="text-white text-lg mb-6 italic">"{content}"</p>
    <div className="flex items-center">
      <div className="w-12 h-12 rounded-full bg-gradient-to-r from-pink-500 to-purple-500 flex items-center justify-center text-white font-bold text-xl">
        {name.charAt(0)}
      </div>
      <div className="ml-4">
        <h4 className="text-white font-bold">{name}</h4>
        <p className="text-gray-300 text-sm">{role} at {company}</p>
      </div>
    </div>
  </motion.div>
);
const PricingCard = ({ tier, price, features, isPopular }) => {
  return (
    <motion.div
      className={`bg-white rounded-2xl shadow-xl p-8 flex flex-col ${isPopular ? 'border-4 border-pink-500' : ''}`}
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      whileHover={{ scale: 1.05 }}
    >
      {isPopular && (
        <span className="bg-pink-500 text-white px-4 py-1 rounded-full text-sm font-bold self-start mb-4">
          Most Popular
        </span>
      )}
      <h3 className="text-2xl font-bold mb-4 text-gray-800">{tier}</h3>
      <p className="text-4xl font-bold mb-6 text-gray-900">${price}<span className="text-xl text-gray-600">/mo</span></p>
      <ul className="mb-8 flex-grow">
        {features.map((feature, index) => (
          <li key={index} className="flex items-center mb-3">
            {feature.included ? (
              <Check className="text-green-500 mr-2" size={20} />
            ) : (
              <X className="text-red-500 mr-2" size={20} />
            )}
            <span className={feature.included ? 'text-gray-700' : 'text-gray-400'}>{feature.text}</span>
          </li>
        ))}
      </ul>
      <button className={`py-3 px-6 rounded-full font-bold text-lg transition-colors duration-300 ${isPopular ? 'bg-pink-500 text-white hover:bg-pink-600' : 'bg-gray-200 text-gray-800 hover:bg-gray-300'}`}>
        Choose Plan
      </button>
    </motion.div>
  );
};

const FeatureCard = ({ icon: Icon, title, description, delay }) => {
    const [isHovered, setIsHovered] = useState(false);
  
    return (
      <motion.div
        className="bg-white bg-opacity-10 backdrop-filter backdrop-blur-lg rounded-xl p-6 flex flex-col items-center text-center transition-all duration-300"
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay }}
        whileHover={{ scale: 1.05, boxShadow: '0 10px 30px -15px rgba(0,0,0,0.3)' }}
        onHoverStart={() => setIsHovered(true)}
        onHoverEnd={() => setIsHovered(false)}
      >
        <motion.div
          animate={{ rotateY: isHovered ? 180 : 0 }}
          transition={{ duration: 0.6 }}
          className="mb-4"
        >
          <Icon size={48} className="text-pink-500" />
        </motion.div>
        <h3 className="text-xl font-bold mb-2 text-white">{title}</h3>
        <p className="text-gray-300">{description}</p>
      </motion.div>
    );
  };

const Media = () => {

  useEffect(() => {
    const handleMouseMove = (event) => {
      setMousePosition({ x: event.clientX, y: event.clientY });
    };

    window.addEventListener('mousemove', handleMouseMove);

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  useEffect(() => {
    const canvas = canvasRef.current;
    const renderer = new THREE.WebGLRenderer({ canvas, alpha: true });
    renderer.setSize(window.innerWidth, window.innerHeight);

    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
    camera.position.z = 5;

    const geometry = new THREE.IcosahedronGeometry(1, 1);
    const material = new THREE.MeshNormalMaterial();
    const cube = new THREE.Mesh(geometry, material);
    scene.add(cube);

    const animate = () => {
      requestAnimationFrame(animate);
      cube.rotation.x += 0.01;
      cube.rotation.y += 0.01;
      renderer.render(scene, camera);
    };
    animate();

    const handleResize = () => {
      camera.aspect = window.innerWidth / window.innerHeight;
      camera.updateProjectionMatrix();
      renderer.setSize(window.innerWidth, window.innerHeight);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const features = [
    {
      icon: TrendingUp,
      title: "Advanced Trading",
      description: "Access powerful tools and real-time market data for optimal trading strategies."
    },
    {
      icon: Shield,
      title: "Top-tier Security",
      description: "Your assets are protected with state-of-the-art encryption and multi-factor authentication."
    },
    {
      icon: Globe,
      title: "Global Accessibility",
      description: "Trade anytime, anywhere with our user-friendly mobile and desktop platforms."
    },
    {
      icon: Zap,
      title: "Instant Transactions",
      description: "Experience lightning-fast transactions with our optimized blockchain network."
    }
  ];

  const [billingCycle, setBillingCycle] = useState('monthly');

  const pricingTiers = [
    {
      tier: 'Basic',
      monthlyPrice: 29,
      yearlyPrice: 290,
      features: [
        { text: 'Basic trading tools', included: true },
        { text: 'Market analysis', included: true },
        { text: 'Mobile app access', included: true },
        { text: 'Priority support', included: false },
        { text: 'Advanced API access', included: false },
      ],
      isPopular: false,
    },
    {
      tier: 'Pro',
      monthlyPrice: 79,
      yearlyPrice: 790,
      features: [
        { text: 'Advanced trading tools', included: true },
        { text: 'Real-time market data', included: true },
        { text: 'Mobile app access', included: true },
        { text: 'Priority support', included: true },
        { text: 'Advanced API access', included: false },
      ],
      isPopular: true,
    },
    {
      tier: 'Enterprise',
      monthlyPrice: 199,
      yearlyPrice: 1990,
      features: [
        { text: 'Full suite of trading tools', included: true },
        { text: 'Institutional-grade data', included: true },
        { text: 'Mobile app access', included: true },
        { text: 'Dedicated support team', included: true },
        { text: 'Custom API solutions', included: true },
      ],
      isPopular: false,
    },
  ];

  const testimonials = [
    {
      name: "Alex Johnson",
      role: "Day Trader",
      company: "Independent",
      content: "CryptoNova has revolutionized my trading experience. The advanced tools and real-time data have significantly improved my decision-making process.",
      rating: 5
    },
    {
      name: "Sarah Lee",
      role: "Investment Manager",
      company: "Global Investments Ltd.",
      content: "The security features of CryptoNova give me peace of mind when handling large transactions. It's a game-changer for institutional investors.",
      rating: 4
    },
    {
      name: "Michael Chen",
      role: "Tech Entrepreneur",
      company: "FutureTech Inc.",
      content: "As someone always on the go, I appreciate the mobile accessibility of CryptoNova. I can manage my portfolio from anywhere in the world.",
      rating: 5
    }
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  const nextTestimonial = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % testimonials.length);
  };

  const prevTestimonial = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + testimonials.length) % testimonials.length);
  };

  useEffect(() => {
    const interval = setInterval(nextTestimonial, 5000);
    return () => clearInterval(interval);
  }, []);


  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const canvasRef = useRef(null);
  const [isHovering, setIsHovering] = useState(false);
  const controls = useAnimation();

  useEffect(() => {
    const handleMouseMove = (event) => {
      setMousePosition({ x: event.clientX, y: event.clientY });
    };

    window.addEventListener('mousemove', handleMouseMove);

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  useEffect(() => {
    const canvas = canvasRef.current;
    const renderer = new THREE.WebGLRenderer({ canvas, alpha: true });
    renderer.setSize(window.innerWidth, window.innerHeight);

    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
    camera.position.z = 5;

    const geometry = new THREE.TorusKnotGeometry(1, 0.3, 100, 16);
    const material = new THREE.MeshNormalMaterial();
    const torusKnot = new THREE.Mesh(geometry, material);
    scene.add(torusKnot);

    const animate = () => {
      requestAnimationFrame(animate);
      torusKnot.rotation.x += 0.01;
      torusKnot.rotation.y += 0.01;
      renderer.render(scene, camera);
    };
    animate();

    const handleResize = () => {
      camera.aspect = window.innerWidth / window.innerHeight;
      camera.updateProjectionMatrix();
      renderer.setSize(window.innerWidth, window.innerHeight);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const featuresTwo = [
    { icon: TrendingUp, text: "Advanced Trading" },
    { icon: Shield, text: "Top-tier Security" },
    { icon: Zap, text: "Lightning-fast Transactions" },
    { icon: Globe, text: "Global Accessibility" }
  ];


  return (
    <>
    <div className="relative min-h-screen bg-gradient-to-br from-purple-900 via-indigo-800 to-blue-900 overflow-hidden">
      <canvas ref={canvasRef} className="absolute inset-0 z-0" />
      <div className="relative z-10 container mx-auto px-4 py-20 flex flex-col justify-center items-center min-h-screen">
        <motion.h1 
          className="text-6xl md:text-8xl font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-pink-500 to-yellow-500 mb-6 text-center"
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
          CryptoNova
        </motion.h1>
        <motion.p 
          className="text-xl md:text-2xl text-white mb-10 max-w-2xl text-center"
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, delay: 0.2 }}
        >
          Revolutionize your financial future with our cutting-edge blockchain technology.
        </motion.p>
        <motion.div 
          className="grid grid-cols-2 md:grid-cols-4 gap-4 mb-10"
          initial={{ opacity: 0, scale: 0.8 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.8, delay: 0.4 }}
        >
          {featuresTwo.map((feature, index) => (
            <motion.div 
              key={index}
              className="flex flex-col items-center bg-white bg-opacity-10 backdrop-filter backdrop-blur-lg rounded-lg p-4"
              whileHover={{ scale: 1.05, backgroundColor: 'rgba(255,255,255,0.2)' }}
            >
              <feature.icon size={32} className="text-pink-500 mb-2" />
              <p className="text-white text-sm text-center">{feature.text}</p>
            </motion.div>
          ))}
        </motion.div>
        <motion.div
          className="relative"
          animate={controls}
          whileHover={{ scale: 1.05 }}
          onHoverStart={() => {
            setIsHovering(true);
            controls.start({ y: -5 });
          }}
          onHoverEnd={() => {
            setIsHovering(false);
            controls.start({ y: 0 });
          }}
        >
          <motion.button 
            className="bg-pink-500 hover:bg-pink-600 text-white font-bold py-3 px-8 rounded-full transition-all duration-300 transform"
            style={{
              boxShadow: `0 0 20px rgba(236, 72, 153, ${isHovering ? '0.8' : '0.5'})`,
              transform: `translate(${(mousePosition.x - window.innerWidth / 2) / 50}px, ${(mousePosition.y - window.innerHeight / 2) / 50}px)`
            }}
          >
            Get Started
          </motion.button>
          {isHovering && (
            <motion.div
              className="absolute -bottom-8 left-1/2 transform -translate-x-1/2 bg-white text-gray-800 px-4 py-2 rounded-lg text-sm"
              initial={{ opacity: 0, y: 10 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 10 }}
            >
              Join 100,000+ traders!
            </motion.div>
          )}
        </motion.div>
      </div>
      <motion.div 
        className="absolute bottom-0 left-0 w-full overflow-hidden z-10"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1, delay: 0.8 }}
      >
        <svg viewBox="0 0 1200 120" preserveAspectRatio="none" className="w-full h-40 text-indigo-900 opacity-50">
          <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" fill="currentColor"></path>
        </svg>
      </motion.div>
    </div>

<section className="py-20 bg-gradient-to-b from-indigo-900 to-purple-900">
<div className="container mx-auto px-4">
  <motion.h2 
    className="text-4xl md:text-5xl font-bold text-center mb-12 text-white"
    initial={{ opacity: 0, y: -50 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.5 }}
  >
    Why Choose CryptoNova?
  </motion.h2>
  <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
    {features.map((feature, index) => (
      <FeatureCard key={index} {...feature} delay={index * 0.1} />
    ))}
  </div>
</div>
<motion.div 
  className="mt-16 text-center"
  initial={{ opacity: 0 }}
  animate={{ opacity: 1 }}
  transition={{ duration: 0.5, delay: 0.5 }}
>
  <a 
    href="#" 
    className="inline-block bg-pink-500 text-white font-bold py-3 px-8 rounded-full hover:bg-pink-600 transition-colors duration-300"
  >
    Explore All Features
  </a>
</motion.div>
</section>

<section className="py-20 bg-gradient-to-b from-purple-900 to-indigo-900 text-white">
      <div className="container mx-auto px-4">
        <motion.h2 
          className="text-4xl md:text-5xl font-bold text-center mb-12"
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          Choose Your Plan
        </motion.h2>
        
        <div className="flex justify-center mb-12">
          <div className="bg-white bg-opacity-20 rounded-full p-1">
            <button
              className={`py-2 px-6 rounded-full font-bold transition-colors duration-300 ${billingCycle === 'monthly' ? 'bg-pink-500 text-white' : 'text-white'}`}
              onClick={() => setBillingCycle('monthly')}
            >
              Monthly
            </button>
            <button
              className={`py-2 px-6 rounded-full font-bold transition-colors duration-300 ${billingCycle === 'yearly' ? 'bg-pink-500 text-white' : 'text-white'}`}
              onClick={() => setBillingCycle('yearly')}
            >
              Yearly
            </button>
          </div>
        </div>
        
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          <AnimatePresence>
            {pricingTiers.map((tier, index) => (
              <PricingCard
                key={tier.tier}
                tier={tier.tier}
                price={billingCycle === 'monthly' ? tier.monthlyPrice : tier.yearlyPrice}
                features={tier.features}
                isPopular={tier.isPopular}
              />
            ))}
          </AnimatePresence>
        </div>
      </div>
    </section>

    <section className="py-20 bg-gradient-to-b from-indigo-900 to-purple-900">
      <div className="container mx-auto px-4">
        <motion.h2 
          className="text-4xl md:text-5xl font-bold text-center mb-12 text-white"
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          What Our Users Say
        </motion.h2>
        <div className="relative max-w-3xl mx-auto">
          <AnimatePresence mode="wait">
            <TestimonialCard key={currentIndex} {...testimonials[currentIndex]} />
          </AnimatePresence>
          <div className="absolute top-1/2 -left-16 transform -translate-y-1/2">
            <button
              onClick={prevTestimonial}
              className="bg-white bg-opacity-20 hover:bg-opacity-30 rounded-full p-2 transition-colors duration-300"
            >
              <ChevronLeft size={24} className="text-white" />
            </button>
          </div>
          <div className="absolute top-1/2 -right-16 transform -translate-y-1/2">
            <button
              onClick={nextTestimonial}
              className="bg-white bg-opacity-20 hover:bg-opacity-30 rounded-full p-2 transition-colors duration-300"
            >
              <ChevronRight size={24} className="text-white" />
            </button>
          </div>
        </div>
        <div className="flex justify-center mt-8">
          {testimonials.map((_, index) => (
            <button
              key={index}
              onClick={() => setCurrentIndex(index)}
              className={`w-3 h-3 rounded-full mx-2 transition-colors duration-300 ${
                index === currentIndex ? 'bg-pink-500' : 'bg-white bg-opacity-30 hover:bg-opacity-50'
              }`}
            />
          ))}
        </div>
      </div>
    </section>


</>
  );
};

export default Media;