import bgCardStrength from './../assets/Strength1.png';

const StrengthCard = ({ icon, title, description, isWhite = false }) => (
    <div className="relative group">
      <div className={`relative h-full w-auto p-4 md:p-10 z-20 rounded-2xl p-4 ${isWhite ? 'text-gray-900' : 'text-white'} transform transition-colors `}>
        <div className="mb-4 animate-fade-in flex justify-center md:justify-start">
          {icon}
        </div>
        <h3 className="text-lg font-semibold mb-2 text-center md:text-left">{title}</h3>
        <p className="text-sm mb-2 text-gray-400 text-center md:text-left">{description}</p>
      </div>
      <div className="absolute inset-0 z-10 overflow-hidden">
        <img
          src={bgCardStrength}
          alt="Crypto landscape illustration"
          className="w-full h-full object-fit opacity-0 transition-opacity duration-300 group-hover:opacity-100"
        />
      </div>
    </div>
  );

  export default StrengthCard;
