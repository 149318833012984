import { useEffect, useRef, useState } from 'react';
import bgFooter from './../assets/BgFooter.png';
import { ivendpayLogo, playbuxLogo, minterestLogo, magicsquareLogo, nibiruLogo, dotinsLogo, forwardLogo, nibiruStoryLogo, myslikoStoryLogo, earnmStoryLogo, storyOne, storyTwo, storyThree, storyFour } from './../images';
import Slider from 'react-slick';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import { Twitter, Instagram, Youtube, Disc, Send, ArrowRight, MessageCircle, Globe, Star, Target, Users } from 'lucide-react';
import { Link } from 'react-router-dom';
import Footer from '../components/footer';
import symbolLogo from './../assets/logo.png';


function Privacy() {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
      setIsMenuOpen(!isMenuOpen);
    };
  
    const scrollToContact = () => {
        const anchor = document.querySelector('#contactus');
        anchor?.scrollIntoView({ behavior: 'smooth', block: 'center' });
      };
    

  return (
    <>
    {/*<div className='Overlay-bg right-[0%] opacity-80'></div>*/}
    <div className=" text-white min-h-screen px-8">
    <header className="flex justify-center items-center mb-2 w-full">
    <nav className="w-full z-50 m-2">
      <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-2">
        <Link to="/" className="flex items-center space-x-3 rtl:space-x-reverse">
        <img src={symbolLogo} class="h-12" alt="KOL Capital Logo" />
          <span className="self-center text-sm font-semibold whitespace-nowrap dark:text-white">KOL Capital</span>
        </Link>
        <div className="flex md:order-2 space-x-3 md:space-x-0 rtl:space-x-reverse items-center">
            <button onClick={scrollToContact} type="button" className="hidden md:block text-black bg-white hover:text-white hover:bg-blue-600 focus:outline-none font-medium rounded-full text-xs px-4 py-2 text-center ">
              Contact Us
            </button>
          <button
            onClick={toggleMenu}
            type="button"
            className="inline-flex items-center p-2 w-8 h-8 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none "
            aria-controls="navbar-sticky"
            aria-expanded={isMenuOpen}
          >
            <span className="sr-only">Open main menu</span>
            <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
              <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 1h15M1 7h15M1 13h15" />
            </svg>
          </button>
        </div>
        <div className={`items-center justify-between w-full md:flex md:w-auto md:order-1 ${isMenuOpen ? 'block bg-black' : 'hidden '}`} id="navbar-sticky">
          <ul className="flex flex-col text-xs p-4 md:p-0 mt-4 font-medium rounded-lg md:space-x-8 rtl:space-x-reverse md:flex-row md:mt-0 md:border-0">
            <li>
              <Link to="#" className="block py-2 px-3 text-white bg-blue-700 rounded md:bg-transparent md:text-white md:p-0 md:dark:text-blue-500" aria-current="page">Home</Link>
            </li>
            <li>
              <Link to="/kols" className="block py-2 px-3 text-white opacity-50 rounded hover:bg-gray-600 md:hover:bg-transparent md:hover:opacity-100 md:p-0 md:dark:hover:text-blue-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700">KOLs Network</Link>
            </li>
            <li>
              <Link to="/portfolio" className="block py-2 px-3 text-white opacity-50 rounded hover:bg-gray-600 md:hover:bg-transparent md:hover:opacity-100 md:p-0 md:dark:hover:text-blue-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700">Portfolio</Link>
            </li>
            <li>
                <Link to="/stories" class="block py-2 px-3 text-white rounded opacity-50 hover:bg-gray-600 md:hover:bg-transparent md:hover:opacity-100 md:p-0 md:dark:hover:text-blue-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700">Stories</Link>
            </li>
            <li>
                <Link to="/testimonials" class="block py-2 px-3 text-white opacity-50 rounded hover:bg-gray-600 md:hover:bg-transparent md:hover:opacity-100 md:p-0 md:dark:hover:text-blue-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700">Testimonials</Link>
            </li>
            <button onClick={scrollToContact} type="button" className="block md:hidden text-black bg-white hover:text-white hover:bg-blue-600 focus:outline-none font-medium rounded-full text-xs px-4 py-2 text-center ">
              Contact Us
            </button>
          </ul>
        </div>
      </div>
    </nav>
      </header>
      <div className='text-wrap whitespace-nowrap max-w-7xl mx-auto'>
      <div className="markdown prose w-full break-words dark:prose-invert dark">
        <div className='text-2xl font-bold mb-5'>Privacy Policy</div>
        <h3>Introduction</h3>
        <p>
            Welcome to KOL Capital. We are committed to protecting your privacy and
            ensuring that your personal information is handled in a safe and responsible
            manner. This Privacy Policy outlines how we collect, use, disclose, and
            protect your information.
        </p>
        <h3>Information We Collect</h3>
        <h4>Personal Information</h4>
        <p>
            We may collect personal information that you provide directly to us, such
            as:
        </p>
        <ul>
            <li>
            <strong>Contact Information:</strong> Name, email address, phone number,
            and mailing address.
            </li>
            <li>
            <strong>Account Information:</strong> Username, password, and other
            authentication details.
            </li>
            <li>
            <strong>Financial Information:</strong> Bank account details, payment
            information, and transaction history.
            </li>
        </ul>
        <h4>Usage Information</h4>
        <p>
            We automatically collect certain information when you visit our website,
            such as:
        </p>
        <ul>
            <li>
            <strong>Log Data:</strong> IP address, browser type, operating system, and
            visit details including pages viewed and links clicked.
            </li>
            <li>
            <strong>Cookies and Tracking Technologies:</strong> We use cookies, web
            beacons, and similar technologies to track your activity on our site and
            gather data about how you interact with our services.
            </li>
        </ul>
        <h3>How We Use Your Information</h3>
        <p>We use the collected information for the following purposes:</p>
        <ul>
            <li>
            <strong>To Provide and Improve Our Services:</strong> Ensure the smooth
            operation of our website and enhance user experience.
            </li>
            <li>
            <strong>To Communicate with You:</strong> Send you updates, newsletters,
            promotional materials, and other information.
            </li>
            <li>
            <strong>To Process Transactions:</strong> Handle payments, investments,
            and other financial activities.
            </li>
            <li>
            <strong>To Maintain Security:</strong> Monitor and protect against
            fraudulent or unauthorized activities.
            </li>
        </ul>
        <h3>Sharing Your Information</h3>
        <p>
            We do not sell, trade, or otherwise transfer your personal information to
            outside parties except as described below:
        </p>
        <ul>
            <li>
            <strong>Service Providers:</strong> We may share your information with
            third-party service providers who assist us in operating our website,
            conducting our business, or providing services to you.
            </li>
            <li>
            <strong>Legal Requirements:</strong> We may disclose your information when
            required to do so by law or in response to legal requests.
            </li>
            <li>
            <strong>Business Transfers:</strong> In the event of a merger,
            acquisition, or sale of all or a portion of our assets, your information
            may be transferred as part of that transaction.
            </li>
        </ul>
        <h3>Data Security</h3>
        <p>
            We implement a variety of security measures to protect your personal
            information from unauthorized access, use, or disclosure. However, please
            note that no method of transmission over the Internet or electronic storage
            is completely secure.
        </p>
        <h3>Your Rights and Choices</h3>
        <p>You have certain rights regarding your personal information, including:</p>
        <ul>
            <li>
            <strong>Access and Correction:</strong> You can access and update your
            personal information by contacting us.
            </li>
            <li>
            <strong>Opt-Out:</strong> You may opt-out of receiving promotional
            communications from us by following the instructions provided in those
            communications.
            </li>
            <li>
            <strong>Cookies:</strong> You can set your browser to refuse all or some
            browser cookies or to alert you when cookies are being sent.
            </li>
        </ul>
        <h3>Third-Party Links</h3>
        <p>
            Our website may contain links to third-party websites. We are not
            responsible for the privacy practices or content of these third-party sites.
        </p>
        <h3>Changes to This Privacy Policy</h3>
        <p>
            We may update this Privacy Policy from time to time. Any changes will be
            posted on this page with an updated revision date. We encourage you to
            review this Privacy Policy periodically to stay informed about how we are
            protecting your information.
        </p>
        <h3>Contact Us</h3>
        <p>
            If you have any questions or concerns about this Privacy Policy, please
            contact us at:
        </p>
        <p>
            <strong>KOL Capital</strong>
            <br />
            Email: [<a rel="noreferrer">your-email@example.com</a>]<br />
            Address: [Your Address]
        </p>
        </div>

    </div>
    </div>

    <div id="contactus">
    <Footer /> 
    </div>


    </>
  );
};

export default Privacy;
