import { useEffect, useRef, useState } from 'react';
import bgFooter from './../assets/BgFooter.png';
import { Twitter, Instagram, Youtube, Disc, Send, ArrowRight, MessageCircle, Globe, Star, Target, Users } from 'lucide-react';
import Slider from 'react-slick';
import { portfolioItems } from './../components/data';
import PortfolioCard from '../components/portfolioCard';
import { Link } from 'react-router-dom';
import Footer from '../components/footer';
import symbolLogo from './../assets/logo.png';


function Portfolio() {

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  const scrollToContact = () => {
    const anchor = document.querySelector('#contactus');
    anchor?.scrollIntoView({ behavior: 'smooth', block: 'center' });
  };

  return (
    <>
    <div className="backgroundColors pb-10">
    {/*<div className='Overlay-bg right-[0%] opacity-80'></div>*/}
    <div className="  text-white min-h-screen">
      <header className="flex justify-center items-center mb-2 w-full">
      <nav className="w-full z-50 m-2">
      <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-2">
        <Link to="/" className="flex items-center space-x-3 rtl:space-x-reverse">
        <img src={symbolLogo} class="h-12" alt="KOL Capital Logo" />
        <span className="self-center text-sm font-semibold whitespace-nowrap dark:text-white">KOL Capital</span>
        </Link>
        <div className="flex md:order-2 space-x-3 md:space-x-0 rtl:space-x-reverse items-center">
            <button onClick={scrollToContact} type="button" className="hidden md:block text-black bg-white hover:text-white hover:bg-blue-600 focus:outline-none font-medium rounded-full text-xs px-4 py-2 text-center ">
              Contact Us
            </button>
          <button
            onClick={toggleMenu}
            type="button"
            className="inline-flex items-center p-2 w-8 h-8 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none "
            aria-controls="navbar-sticky"
            aria-expanded={isMenuOpen}
          >
            <span className="sr-only">Open main menu</span>
            <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
              <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 1h15M1 7h15M1 13h15" />
            </svg>
          </button>
        </div>
        <div className={`items-center justify-between w-full md:flex md:w-auto md:order-1 ${isMenuOpen ? 'block bg-black' : 'hidden '}`} id="navbar-sticky">
          <ul className="flex flex-col text-xs p-4 md:p-0 mt-4 font-medium rounded-lg md:space-x-8 rtl:space-x-reverse md:flex-row md:mt-0 md:border-0">
            <li>
                <Link to="/" class="block py-2 px-3 text-white rounded opacity-50 hover:bg-gray-600 md:hover:bg-transparent md:hover:opacity-100 md:p-0 md:dark:hover:text-blue-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700">Home</Link>
            </li>
            <li>
                <Link to="#" class="block py-2 px-3 text-white bg-blue-700  rounded md:bg-transparent md:p-0 md:dark:text-blue-500" aria-current="page">Portfolio</Link>
            </li>
            <li>
            <Link to="/kols" className="block py-2 px-3 text-white rounded opacity-50 hover:bg-gray-600 md:hover:bg-transparent md:hover:opacity-100 md:p-0 md:dark:hover:text-blue-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700">Networks</Link>
            </li>
            <li>
              <Link to="/#team" className="block cursor-pointer py-2 px-3 text-white opacity-50 rounded hover:bg-gray-600 md:hover:bg-transparent md:hover:opacity-100 md:p-0 md:dark:hover:text-blue-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700">Team</Link>
            </li>
            <li>
                <Link to="/stories" class="block py-2 px-3 text-white rounded opacity-50 hover:bg-gray-600 md:hover:bg-transparent md:hover:opacity-100 md:p-0 md:dark:hover:text-blue-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700">Case Studies</Link>
            </li>
            <li>
                <Link to="/testimonials" class="block py-2 px-3 text-white rounded opacity-50 hover:bg-gray-600 md:hover:bg-transparent md:hover:opacity-100 md:p-0 md:dark:hover:text-blue-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700">Testimonials</Link>
            </li>
            <button onClick={scrollToContact} type="button" className="block md:hidden text-black bg-white hover:text-white hover:bg-blue-600 focus:outline-none font-medium rounded-full text-xs px-4 py-2 text-center ">
              Contact Us
            </button>
            </ul>
        </div>
        </div>
        </nav>
      </header>
      <div className='max-w-7xl mx-auto'>
      <div className='max-w-7xl grid grid-cols-1 gap-8 mx-auto mt-10 text-center'>
        <h2 className="text-4xl">Portfolio</h2>
        <p className="mb-6 text-sm text-gray-400">
        Highlighting our strategic investments and the transformative impact they have on the Web3 landscape
        </p>
      </div>
      <div className="grid grid-cols-3 md:grid-cols-3 lg:grid-cols-4 gap-5 mb-10 items-center mx-auto">
      {portfolioItems.map((item, index) => (
        <div className='flex items-start justify-center'>
          <PortfolioCard key={index} {...item} />
        </div>
      ))}
      </div>
      </div>


    </div>
    </div>
    <div id="contactus">
    <Footer /> 
    </div>


    </>
  );
};

export default Portfolio;
