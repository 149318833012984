import { useEffect, useRef, useState } from 'react';
import bgFooter from './../assets/BgFooter.png';
import { ivendpayLogo, playbuxLogo, minterestLogo, magicsquareLogo, nibiruLogo, dotinsLogo, forwardLogo, nibiruStoryLogo, myslikoStoryLogo, earnmStoryLogo, storyOne, storyTwo, storyThree, storyFour } from '../images';
import Slider from 'react-slick';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import { Twitter, Instagram, Youtube, Disc, Send, ArrowRight, MessageCircle, Globe, Star, Target, Users } from 'lucide-react';
import { Link } from 'react-router-dom';
import Footer from '../components/footer';
import symbolLogo from './../assets/logo.png';


function Terms() {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
      setIsMenuOpen(!isMenuOpen);
    };
  
    const scrollToContact = () => {
        const anchor = document.querySelector('#contactus');
        anchor?.scrollIntoView({ behavior: 'smooth', block: 'center' });
      };
    

  return (
    <>
    {/*<div className='Overlay-bg right-[0%] opacity-80'></div>*/}
    <div className=" text-white min-h-screen px-8">
    <header className="flex justify-center items-center mb-2 w-full">
    <nav className="w-full z-50 m-2">
      <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-2">
        <Link to="/" className="flex items-center space-x-3 rtl:space-x-reverse">
        <img src={symbolLogo} class="h-12" alt="KOL Capital Logo" />
          <span className="self-center text-sm font-semibold whitespace-nowrap dark:text-white">KOL Capital</span>
        </Link>
        <div className="flex md:order-2 space-x-3 md:space-x-0 rtl:space-x-reverse items-center">
            <button onClick={scrollToContact} type="button" className="hidden md:block text-black bg-white hover:text-white hover:bg-blue-600 focus:outline-none font-medium rounded-full text-xs px-4 py-2 text-center ">
              Contact Us
            </button>
          <button
            onClick={toggleMenu}
            type="button"
            className="inline-flex items-center p-2 w-8 h-8 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none "
            aria-controls="navbar-sticky"
            aria-expanded={isMenuOpen}
          >
            <span className="sr-only">Open main menu</span>
            <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
              <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 1h15M1 7h15M1 13h15" />
            </svg>
          </button>
        </div>
        <div className={`items-center justify-between w-full md:flex md:w-auto md:order-1 ${isMenuOpen ? 'block bg-black' : 'hidden '}`} id="navbar-sticky">
          <ul className="flex flex-col text-xs p-4 md:p-0 mt-4 font-medium rounded-lg md:space-x-8 rtl:space-x-reverse md:flex-row md:mt-0 md:border-0">
            <li>
              <Link to="#" className="block py-2 px-3 text-white bg-blue-700 rounded md:bg-transparent md:text-white md:p-0 md:dark:text-blue-500" aria-current="page">Home</Link>
            </li>
            <li>
              <Link to="/kols" className="block py-2 px-3 text-white opacity-50 rounded hover:bg-gray-600 md:hover:bg-transparent md:hover:opacity-100 md:p-0 md:dark:hover:text-blue-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700">KOLs Network</Link>
            </li>
            <li>
              <Link to="/portfolio" className="block py-2 px-3 text-white opacity-50 rounded hover:bg-gray-600 md:hover:bg-transparent md:hover:opacity-100 md:p-0 md:dark:hover:text-blue-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700">Portfolio</Link>
            </li>
            <li>
                <Link to="/stories" class="block py-2 px-3 text-white rounded opacity-50 hover:bg-gray-600 md:hover:bg-transparent md:hover:opacity-100 md:p-0 md:dark:hover:text-blue-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700">Stories</Link>
            </li>
            <li>
                <Link to="/testimonials" class="block py-2 px-3 text-white opacity-50 rounded hover:bg-gray-600 md:hover:bg-transparent md:hover:opacity-100 md:p-0 md:dark:hover:text-blue-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700">Testimonials</Link>
            </li>
            <button onClick={scrollToContact} type="button" className="block md:hidden text-black bg-white hover:text-white hover:bg-blue-600 focus:outline-none font-medium rounded-full text-xs px-4 py-2 text-center ">
              Contact Us
            </button>
          </ul>
        </div>
      </div>
    </nav>
      </header>
      <div className=' max-w-7xl mx-auto'>
      <div classname="">
        <div className='text-2xl font-bold mb-5'>Terms of Service</div>
        <h3>Introduction</h3>
        <p>
            Welcome to KOL Capital. By accessing or using our website, you agree to
            comply with and be bound by the following terms and conditions. Please read
            them carefully. If you do not agree to these terms, you should not use our
            website.
        </p>
        <h3>Use of the Site</h3>
        <h4>Eligibility</h4>
        <p>
            By using our site, you represent and warrant that you are at least 18 years
            old and have the legal capacity to enter into these terms.
        </p>
        <h4>Account Registration</h4>
        <p>
            To access certain features of our website, you may need to create an
            account. You agree to provide accurate, current, and complete information
            during the registration process and to update such information to keep it
            accurate, current, and complete. You are responsible for maintaining the
            confidentiality of your account credentials and for all activities that
            occur under your account.
        </p>
        <h3>User Conduct</h3>
        <p>
            You agree not to use our website for any unlawful purpose or in any way that
            could harm, disrupt, or otherwise negatively impact KOL Capital or any third
            party. Prohibited activities include, but are not limited to:
        </p>
        <ul>
            <li>Engaging in any fraudulent activity.</li>
            <li>Attempting to gain unauthorized access to our systems or networks.</li>
            <li>Uploading or transmitting any harmful code or content.</li>
            <li>Interfering with the proper functioning of our website.</li>
        </ul>
        <h3>Intellectual Property</h3>
        <p>
            All content on our website, including text, graphics, logos, images, and
            software, is the property of KOL Capital or its licensors and is protected
            by intellectual property laws. You may not use, reproduce, distribute, or
            create derivative works from any content without our prior written
            permission.
        </p>
        <h3>Disclaimers</h3>
        <p>
            Our website and services are provided "as is" and "as available" without
            warranties of any kind, either express or implied. KOL Capital does not
            warrant that the site will be uninterrupted, error-free, or free of viruses
            or other harmful components.
        </p>
        <h3>Limitation of Liability</h3>
        <p>
            To the fullest extent permitted by law, KOL Capital shall not be liable for
            any direct, indirect, incidental, special, or consequential damages arising
            out of or in connection with your use of our website or these terms. This
            limitation applies regardless of the legal theory upon which the claim is
            based.
        </p>
        <h3>Indemnification</h3>
        <p>
            You agree to indemnify, defend, and hold harmless KOL Capital, its
            affiliates, officers, directors, employees, and agents from and against any
            and all claims, damages, losses, liabilities, costs, and expenses arising
            from or related to your use of our website or any violation of these terms.
        </p>
        <h3>Governing Law</h3>
        <p>
            These terms shall be governed by and construed in accordance with the laws
            of the jurisdiction in which KOL Capital operates, without regard to its
            conflict of law principles. You agree to submit to the exclusive
            jurisdiction of the courts located in that jurisdiction for the resolution
            of any disputes.
        </p>
        <h3>Changes to These Terms</h3>
        <p>
            We may update these terms from time to time. Any changes will be posted on
            this page with an updated revision date. Your continued use of our website
            after any changes indicates your acceptance of the new terms.
        </p>
        <h3>Contact Us</h3>
        <p>
            If you have any questions or concerns about these Terms of Service, please
            contact us at:
        </p>
        <p>
            <strong>KOL Capital</strong>
            <br />
            Email: [<a rel="noreferrer">your-email@example.com</a>]<br />
            Address: [Your Address]
        </p>
        </div>
        </div>
    </div>

    <div id="contactus">
    <Footer /> 
    </div>


    </>
  );
};

export default Terms;
