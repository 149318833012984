import bgInvestor from './../assets/Investor1.png';
import { ivendpayLogo, playbuxLogo, mystickLogo, earnmLogo, minterestLogo, magicsquareLogo, nibiruLogo, dotinsLogo, forwardLogo, nibiruStoryLogo, myslikoStoryLogo, earnmStoryLogo, storyOne, storyTwo, storyThree, storyFour,AethirLogo,BlockroadsLogo ,DarewiseLogo ,KelpDaoLogo,LifeFormLogo,MatchChainLogo ,NOTLDLogo ,PartiLogo,PartisiaLogo,RevolvingGamesLogo,RTFLogo,UdgardLogo, bgkol9, bgkol10, bgkol11, bgkol12, bgkol13, bgkol14, bgkol15, bgkol16, bgkol17, bgkol18, bgkol19, bgkol20, bgkol21, bgkol22, bgkol23, bgkol24, bgkol25, bgkol26, bgkol27, bgkol28, bgkol29, bgkol30, bgkol31, bgkol32, bgkol33, bgkol34, bgkol35, bgkol36, bgkol37, bgkol38, bgkol39, bgkol40, bgkol41, bgkol42, bgkol43, bgkol44, bgkol46, bgkol47, bgkol48, bgkol49, bgkol50, bgkol51, bgkol52, bgkol53, bgkol54, bgkol55, bgkol45, bgkol61, bgkol62, bgkol63, bgkol64, bgkol65, bgkol66, bgkol56, bgkol57, bgkol58, bgkol59, bgkol60, bgkol67, bgkol68, bgkol69, bgkol70, bgkol71, bgkol72, bgkol73, bgkol74, bgkol75, bgkol76, bgkol77, bgkol78, bgkol79, bgkol80, bgkol81, bgkol82, bgkol83, bgkol84, bgkol85, bgkol86, bgkol87, bgkol88, bgkol89, bgkol90, bgkol91, bgkol92
} from './../images';
import { bgEvanLuthra, bgCoinMag, bgErhanUnal, bgMustafaIspir, bgKahin, bgBorsa, bgSkylineeth, bgInvestor10, bgInvestor6, bgInvestor7, bgInvestor8, bgInvestor9, bgInvestor11, bgInvestor12, bgInvestor13, bgInvestor14, bgInvestor15, bgInvestor16, bgInvestor17, bgInvestor18, bgInvestor19, bgInvestor20, bgInvestor21, bgInvestor22, bgInvestor23, bgInvestor24, bgInvestor25, bgInvestor26, bgInvestor27, bgInvestor28, bgInvestor29 } from './../images';
import { bgkol1, bgkol2, bgkol3, bgkol4, bgkol5, bgkol6, bgkol7, bgkol8 } from './../images';




export const investors = [
    {
      name: "Evan Luthra",
      country: "India",
      title: "Serial Enterpreneur",
      imageSrc: bgEvanLuthra,
      socialLinks: [
        { platform: 'twitter', url: 'https://twitter.com/evanluthra' },
        { platform: 'instagram', url: 'https://instagram.com/evanluthra' },
        { platform: 'youtube', url: 'https://youtube.com/c/evanluthra' },
        { platform: 'telegram', url: 'https://t.me/EvanLuthraVIP' },
      ]
    },
    {
      name: "Mag",
      country: "Turkey",
      title: "Serial Enterpreneur",
      imageSrc: bgCoinMag,
      socialLinks: [
        { platform: 'twitter', url: 'https://twitter.com/btcoinmag' },
      ]
    },
    {
      name: "Erhan Ünal",
      country: "Turkey",
      title: "Serial Enterpreneur",
      imageSrc: bgErhanUnal,
      socialLinks: [
        { platform: 'twitter', url: 'https://x.com/erhanunall' },
      ]
    },
    {
      name: "Turk Elite",
      country: "Turkey",
      title: "Serial Enterpreneur",
      imageSrc: bgMustafaIspir,
      socialLinks: [
        { platform: 'twitter', url: 'http://x.com/turkelit' },
        { platform: 'youtube', url: 'https://YouTube.com/c/elitturk' },
      ]
    },
    {
      name: "Borsa",
      country: "Turkey",
      title: "Serial Enterpreneur",
      imageSrc: bgBorsa,
      socialLinks: [
        { platform: 'twitter', url: 'https://twitter.com/Borsayolundaa' },
      ]
    },
    {
      name: "Skylineeth",
      country: "Turkey",
      title: "Serial Enterpreneur",
      imageSrc: bgSkylineeth,
      socialLinks: [
        { platform: 'twitter', url: 'https://x.com/Skylineeth' },
        { platform: 'discord', url: 'https://discord.gg/NFTHunters' },
      ]
    },
    {
      name: "Teknikci Hoca",
      country: "Turkey",
      title: "Serial Enterpreneur",
      imageSrc: bgInvestor7,
      socialLinks: [
        { platform: 'twitter', url: 'https://twitter.com/TeknikciHoca' },
        { platform: 'telegram', url: 'http://t.me/teknikci_hoca' },
      ]
    },
    {
      name: "İnanç Salman",
      country: "Turkey",
      title: "Serial Enterpreneur",
      imageSrc: bgInvestor6,
      socialLinks: [
        { platform: 'twitter', url: 'https://x.com/inancsalman' },
        { platform: 'instagram', url: 'https://www.instagram.com/inanc.salman/' },
      ]
    },
    {
      name: "Expert Kripto",
      country: "Turkey",
      title: "Serial Enterpreneur",
      imageSrc: bgInvestor8,
      socialLinks: [
        { platform: 'twitter', url: 'https://twitter.com/expert_kripto' },
        { platform: 'youtube', url: 'https://www.youtube.com/c/ExpertKripto' },
      ]
    },
    {
      name: "Kadir",
      country: "Turkey",
      title: "Serial Enterpreneur",
      imageSrc: bgInvestor9,
      socialLinks: [
        { platform: 'twitter', url: 'https://x.com/KadirCalimbay' },
      ]
    },
    {
      name: "Roman",
      country: "Turkey",
      title: "Serial Enterpreneur",
      imageSrc: bgInvestor10,
      socialLinks: [
        { platform: 'twitter', url: 'https://x.com/0x_Roman' },
      ]
    },
    {
      name: "Kahin",
      country: "Turkey",
      title: "Serial Enterpreneur",
      imageSrc: bgKahin,
      socialLinks: [
        { platform: 'twitter', url: 'https://x.com/0x_Roman' },
      ]
    },
    {
      name: "Nguyen Duc Minh",
      country: "Vietnam",
      title: "Serial Enterpreneur",
      imageSrc: bgInvestor11,
      socialLinks: [
        { platform: 'telegram', url: 'https://t.me/ubholdcoin, ' },
      ]
    },
    {
      name: "DusFi",
      country: "Vietnam",
      title: "Serial Enterpreneur",
      imageSrc: bgInvestor12,
      socialLinks: [
        { platform: 'twitter', url: 'https://x.com/dus_fi' },
      ]
    },
    {
      name: "Dung Le",
      country: "Vietnam",
      title: "Serial Enterpreneur",
      imageSrc: bgInvestor13,
      socialLinks: [
        { platform: 'telegram', url: 'https://t.me/gemcapital999' },
        { platform: 'twitter', url: 'https://twitter.com/gemcapital999' },
      ]
    },
    {
      name: "Ri OG",
      country: "Vietnam",
      title: "Serial Enterpreneur",
      imageSrc: bgInvestor14,
      socialLinks: [
        { platform: 'telegram', url: 'https://t.me/BSC_GemsSniper' },
        { platform: 'twitter', url: 'https://twitter.com/RitheOG' },
      ]
    },
    {
      name: "Sumit Kapoor",
      country: "India",
      title: "Serial Enterpreneur",
      imageSrc: bgInvestor15,
      socialLinks: [
        { platform: 'twitter', url: 'https://x.com/wiseadvicesumit?t=YFnChJD_tfAIWxrKGqq-dw&s=09' },
        { platform: 'youtube', url: 'https://youtube.com/@WISEADVICEE' },
        { platform: 'telegram', url: 'https://t.me/wiseadvicebysumit' },
      ]
    },
    {
      name: "Karan Singh Arora",
      country: "India",
      title: "Serial Enterpreneur",
      imageSrc: bgInvestor16,
      socialLinks: [
        { platform: 'telegram', url: 'https://t.me/cryptoksaofficial' },
        { platform: 'twitter', url: 'https://twitter.com/thisisksa' },
        { platform: 'instagram', url: 'https://www.instagram.com/karansingharoraofficial' },
      ]
    },
    {
      name: "Pushpendra Singh Digital",
      country: "India",
      title: "Serial Enterpreneur",
      imageSrc: bgInvestor17,
      socialLinks: [
        { platform: 'youtube', url: 'https://www.youtube.com/@PUSHPENDRASINGHDIGITAL' },
        { platform: 'twitter', url: 'https://x.com/PushpendraTech?t=u0FXo5wrPa3MjuJ8OYQkRA&s=09' },
      ]
    },
    {
      name: "Crypto King Keyur",
      country: "India",
      title: "Serial Enterpreneur",
      imageSrc: bgInvestor18,
      socialLinks: [
        { platform: 'twitter', url: 'https://x.com/CryptoKingKeyur' },
        { platform: 'telegram', url: 'https://t.me/CryptoTalksBudhil' },
        { platform: 'youtube', url: 'https://www.youtube.com/cryptokingkeyur' },
      ]
    },
    {
      name: "Budhil Vyas",
      country: "India",
      title: "Serial Enterpreneur",
      imageSrc: bgInvestor19,
      socialLinks: [
        { platform: 'telegram', url: 'https://t.me/CryptoTalksBudhil' },
        { platform: 'twitter', url: 'https://x.com/BudhilVyas' },
        { platform: 'youtube', url: 'https://www.youtube.com/c/CryptoTalksBudhil' },
      ]
    },
    {
      name: "Crypto Dep",
      country: "Ukraine",
      title: "Serial Enterpreneur",
      imageSrc: bgInvestor20,
      socialLinks: [
        { platform: 'twitter', url: 'https://x.com/Crypto_Dep' },
      ]
    },
    {
      name: "Top7ICO",
      country: "Ukraine",
      title: "Serial Enterpreneur",
      imageSrc: bgInvestor21,
      socialLinks: [
        { platform: 'telegram', url: 'https://t.me/top7ico' },
        { platform: 'twitter', url: 'https://twitter.com/top7ico' },
      ]
    },
    {
      name: "Crypto Differ",
      country: "Ukraine",
      title: "Serial Enterpreneur",
      imageSrc: bgInvestor22,
      socialLinks: [
        { platform: 'twitter', url: 'https://twitter.com/CryptoDiffer' },
      ]
    },
    {
      name: "DoubleTop",
      country: "Ukraine",
      title: "Serial Enterpreneur",
      imageSrc: bgInvestor23,
      socialLinks: [
        { platform: 'twitter', url: 'https://twitter.com/doubletop_io' },
        { platform: 'instagram', url: 'https://www.instagram.com/doubletop.io/' },
        { platform: 'telegram', url: 'https://t.me/doubletop' },
        { platform: 'youtube', url: 'https://www.youtube.com/c/DOUBLETOP_IO' },
      ]
    },
    {
      name: "Adrián Golub",
      country: "South America",
      title: "Serial Enterpreneur",
      imageSrc: bgInvestor24,
      socialLinks: [
        { platform: 'instagram', url: 'https://instagram.com/adrian_golub?igshid=OGQ5ZDc2ODk2ZA%3D%3D&utm_source=qr' },
      ]
    },
    {
      name: "Daniel Fuentes Jimenez",
      country: "South America",
      title: "Serial Enterpreneur",
      imageSrc: bgInvestor25,
      socialLinks: [
        { platform: 'twitter', url: 'https://x.com/danfuentes_' },
        { platform: 'youtube', url: 'https://youtube.com/@dan_fuentes?si=iSX8iseQmsJqNPv7' },
      ]
    },
    {
      name: "Tasso Lago",
      country: "South America",
      title: "Serial Enterpreneur",
      imageSrc: bgInvestor26,
      socialLinks: [
        { platform: 'twitter', url: 'https://X.com/@lagotasso' },
        { platform: 'instagram', url: 'https://www.Instagram.com/tassolago' },
      ]
    },
    {
      name: "Rodrigo Luciano de Souza",
      country: "South America",
      title: "Serial Enterpreneur",
      imageSrc: bgInvestor27,
      socialLinks: [
        { platform: 'twitter', url: 'https://twitter.com/Rosouzapro' },
        { platform: 'instagram', url: 'https://www.instagram.com/cripto.rodrigosouzapro/' },
        { platform: 'telegram', url: 'https://t.me/rodrigosouzapro' },
      ]
    },
    {
      name: "Insalubres",
      country: "South America",
      title: "Serial Enterpreneur",
      imageSrc: bgInvestor28,
      socialLinks: [
        { platform: 'twitter', url: 'https://x.com/insalubresbtc' },
        { platform: 'instagram', url: 'https://instagram.com/insalubrescripto' },
        { platform: 'telegram', url: 'https://t.me/insalubrescripto1' },
        { platform: 'youtube', url: 'https://youtube.com/c/insalubrescripto' },
      ]
    },
    {
      name: "Caio Villa",
      country: "South America",
      title: "Serial Enterpreneur",
      imageSrc: bgInvestor29,
      socialLinks: [
        { platform: 'twitter', url: 'https://twitter.com/CriptoVilla' },
        { platform: 'instagram', url: 'https://www.instagram.com/CriptoVilla' },
        { platform: 'youtube', url: 'https://youtube.com/@CaioVilla' },
      ]
    },

  ];

export  const portfolioItems = [
    {
      logo: { src: ivendpayLogo},
      name: "Ivendpay",
      category: "Payment",
      website: "https://ivendpay.com/",
      bgColor: "bg-white"
    },
    {
      logo: { src: forwardLogo },
      name: "Forward",
      category: "",
      website: "https://forwardprotocol.io/",
      bgColor: "bg-indigo-900"
    },
    {
      logo: { src: playbuxLogo },
      name: "Playbux",
      category: "GameFi,NFT",
      website: "https://www.playbux.co/",
      bgColor: "bg-indigo-900"
    },
    {
      logo: { src: mystickLogo },
      name: "Mystiko ",
      category: "",
      website: "https://mystiko.network/",
      bgColor: "bg-indigo-900"
    },
    {
      logo: { src: nibiruLogo },
      name: "Nibiru Chain",
      category: "L1",
      website: "https://nibiru.fi/",
      bgColor: "bg-indigo-900"
    },
    {
      logo: { src: earnmLogo },
      name: "EARNM Rewards",
      category: "DePIN",
      website: "https://earnm.com",
      bgColor: "bg-indigo-900"
    },
    {
      logo: { src: minterestLogo },
      name: "Minterest",
      category: "",
      website: "https://minterest.com/",
      bgColor: "bg-indigo-900"
    },
    {
      logo: { src: magicsquareLogo },
      name: "Magic Square",
      category: "Marketplace",
      website: "https://magicsquare.io/",
      bgColor: "bg-indigo-900"
    },
    {
      logo: { src: dotinsLogo },
      name: "DOTIns",
      category: "Inscriptions",
      website: "https://Dotins.io",
      bgColor: "bg-indigo-900"
    },
    {
      logo: { src: RTFLogo },
      name: "Ready to fight",
      category: "SocialFi",
      website: "https://rtfight.com/blockchain",
      bgColor: "bg-indigo-900"
    },
    {
      logo: { src: BlockroadsLogo },
      name: "Blocklords",
      category: "GameFi",
      website: "https://blocklords.com/",
      bgColor: "bg-indigo-900"
    },
    {
      logo: { src: DarewiseLogo },
      name: "Darewise",
      category: "BRC20",
      website: "https://darewise.com/",
      bgColor: "bg-indigo-900"
    },
    {
      logo: { src: RevolvingGamesLogo },
      name: "Revolving Games",
      category: "GameFi",
      website: "https://revolvinggames.com/",
      bgColor: "bg-indigo-900"
    },
    {
      logo: { src: PartisiaLogo },
      name: "Partisia Blockchain",
      category: "L1",
      website: "https://partisiablockchain.com/",
      bgColor: "bg-indigo-900"
    },
    {
      logo: { src: AethirLogo },
      name: "Aethir Nodes",
      category: "DePIN",
      website: "https://www.aethir.com/",
      bgColor: "bg-indigo-900"
    },
    {
      logo: { src: PartiLogo },
      name: "Parti",
      category: "SocialFi",
      website: "http://parti.com/",
      bgColor: "bg-indigo-900"
    },
    {
      logo: { src: UdgardLogo },
      name: "Utgard",
      category: "GameFi",
      website: "http://Utgard.io",
      bgColor: "bg-indigo-900"
    },
    {
      logo: { src: NOTLDLogo },
      name: "Night of The Living Dead",
      category: "GameFi",
      website: "https://www.nightofthelivingdead.xyz/",
      bgColor: "bg-indigo-900"
    },
    {
      logo: { src: MatchChainLogo },
      name: "Match Chain",
      category: "",
      website: "https://matchain.io/",
      bgColor: "bg-indigo-900"
    },
    {
      logo: { src: KelpDaoLogo },
      name: "KelpDao",
      category: "",
      website: "https://www.kelpdao.xyz/",
      bgColor: "bg-indigo-900"
    },

  ];

export  const stories = [
    {
      logo: nibiruLogo,
      title: "Nibiru",
      subtitle: "Revolutionizing the Crypto Landscape with Strategic KOL Engagement",
      description: "KOL Capital  played a pivotal role in the early-stage success of Nibiru, a groundbreaking project in the cryptocurrency space. Recognizing Nibiru's potential, we meticulously handpicked high-quality Key Opinion Leaders (KOLs) who aligned with the project's vision. We onboarded over 60 influential KOLs, ensuring their investment in Nibiru and their active promotion across their social platforms. Our strategic approach spanned both pre-TGE and post-TGE phases. Before the TGE, we led KOL management and marketing efforts, building a strong foundation of trust and anticipation around Nibiru. Post-TGE, our continued engagement and strategic promotions helped maintain momentum, significantly boosting Nibiru's market presence and credibility.",
      description2: "The results were extraordinary: Nibiru achieved a remarkable 20x increase on launch. This success highlighted the effectiveness of our strategic KOL engagement and marketing efforts. By leveraging the influence of top-tier KOLs, we facilitated substantial investment in Nibiru and amplified its visibility and appeal within the cryptocurrency community. Our partnership with Nibiru exemplifies our commitment to supporting innovative projects through strategic engagement and targeted marketing, ensuring their sustained growth and success in the competitive crypto market.",
      bgColor: "bg-white",
      textColor: "text-gray-800",
      logoBackground: "bg-indigo-900",
      storyBg: storyOne,
      storyBgWhite: storyTwo,
    },
    {
      logo: PartiLogo,
      title: "Partisia",
      subtitle: "Strategic Fundraising and KOL Management",
      description: "At KOL Capital, our strategic efforts were instrumental in the success of Partisia, a promising project in the blockchain space. We managed to raise over $1M for Partisia, leveraging our extensive network of Key Opinion Leaders (KOLs). By onboarding over 80 influential KOLs, we ensured continuous support and promotion for Partisia at every step. These KOLs actively promoted Partisia across their social platforms, significantly amplifying its reach and visibility.",
      description2: "Our impactful engagement didn't stop there. Due to the overwhelming support and demand from our KOLs, we secured extra allocation for Partisia for our KOLs. This additional allocation was a direct result of our ability to effectively spread the word and generate substantial interest in the project. Our partnership with Partisia showcases our commitment to driving success through strategic fundraising and comprehensive KOL management, ensuring sustained growth and market presence.",
      bgColor: "bg-indigo-900",
      textColor: "text-white",
      storyBg: storyThree,
      storyBgWhite: storyFour,
    },
    {
      logo: mystickLogo,
      title: "Mystiko Network",
      subtitle: "Exceptional Fundraising and Unwavering Support",
      description: "At KOL Capital, We raised $1.3M for Mystiko, ensuring they had the necessary funds to propel their vision forward. Our efforts extended beyond fundraising; we also played a crucial role in marketing, helping to push out their major updates and generate widespread interest.",
      description2: "Our extensive network of over 150 KOLs from KOL Capital invested in Mystiko and provided unwavering support. These influential KOLs actively promoted Mystiko across their social platforms, amplifying its reach and ensuring its key updates received the attention they deserved. Despite a rough launch due to challenging market conditions, we never ceased our support for Mystiko. Our commitment to their success remained steadfast, demonstrating our dedication to fostering growth and long-term sustainability through strategic fundraising and robust marketing support.",
      bgColor: "bg-indigo-900",
      textColor: "text-white",
      logoBackground: "bg-black",
      storyBg: storyOne,
      storyBgWhite: storyTwo,
    },
    {
      logo: earnmLogo,
      title: "EARN’M",
      subtitle: "Strategic Fundraising and Top-Tier KOL Engagement",
      description: "KOL Capital raised over half a million dollars for Earn'M, providing the necessary financial foundation for their growth. Our strategic approach included onboarding over 80 handpicked top KOLs who aligned with Earn'M's vision and goals.",
      description2: "These influential KOLs not only invested in Earn'M but also actively promoted the project across their social platforms. Our marketing efforts were instrumental in spreading the word about Earn'M, significantly enhancing its visibility and market presence. Our partnership with Earn'M showcases our expertise in strategic fundraising and effective KOL engagement, ensuring their continued growth and success in the competitive blockchain market.",
      bgColor: "bg-indigo-900",
      textColor: "text-white",
      logoBackground: "bg-black",
      storyBg: storyOne,
      storyBgWhite: storyTwo,
    },
    {
      logo: MatchChainLogo,
      title: "Match Chain",
      subtitle: "Boosting User Engagement and Network Growth",
      description: "KOL Capital significantly enhanced MatchChain's growth trajectory by leveraging our extensive network of influential Key Opinion Leaders (KOLs). We onboarded over 80 KOLs who believed in MatchChain's vision and actively promoted the project, helping to attract a broader user base.",
      description2: "Before our involvement, MatchChain had achieved notable statistics with 105 million transactions since June 2023, 350,000 daily average users, and over 800,000 DID/SBT users. After partnering with KOL Capital, MatchChain saw remarkable growth, reaching 124 million transactions, 956,000+ DID users, and 174,000+ daily average users.Our targeted KOL engagement and marketing efforts were instrumental in driving these impressive results, showcasing our commitment to supporting innovative projects and fostering their growth in the competitive blockchain landscape.",
      bgColor: "bg-indigo-900",
      textColor: "text-white",
      storyBg: storyThree,
      storyBgWhite: storyFour,
    },
  ];

export  const testimonials = [
    {
      id: 1,
      content: "I've had the pleasure of collaborating with KOL Capital and can confidently say they consistently provide top-tier deals. The team at KOL Capital is not only professional and responsive, but they also truly understand the needs of their clients, making every partnership a seamless experience. Their dedication to delivering exclusive, high-quality opportunities has significantly contributed to my success. I'm thoroughly impressed with their commitment to building strong, effective relationships with key opinion leaders.",
      author: "IBCIG",
      link: "https://twitter.com/ibcig",
      avatar: "https://pbs.twimg.com/profile_images/1597124796751302656/-yAwhFdC_400x400.jpg"
    },
    {
      id: 2,
      content: "Kol Kapital offers us many great opportunities, we cannot find these great opportunities offered by Kol Kapital anywhere else. Some of the opportunities that Kol Kapital offers us , OTC opportunities, Opportunity to access projects that have never been to Launchpad, Opportunity to purchase projects with very nice vestments, reliability",
      author: "Patron",
      link: "https://twitter.com/genelpatron01",
      avatar: "https://pbs.twimg.com/profile_images/1458719775400640516/pb76ZHav_400x400.jpg"
    },
    {
      id: 3,
      content: "KOL Capital is the place where I got the opportunity to invest in some good deals and I appreciate the effort the team puts into bringing us the deal flow",
      author: "Vlad Stoica",
      link: "https://www.instagram.com/vladstoica",
      avatar: "https://pbs.twimg.com/profile_images/1764816314420350977/gBUQTjbd_400x400.jpg"
    },
    {
      id: 4,
      content: "It is always a pleasure to work with KOL Capital team. Evan, Sushil, and Sumit run the company in the best practices of decentralization, allowing every interested party to participate and bring value to the projects in return. They treat all KOLs with respect and are ready to defend the interests of investors where conflict situations arise. I believe the projects get a very good deal by working with KOL Capital, securing both a great amount of media coverage and sufficient funding for development",
      author: "CryptoDiffer",
      link: "https://twitter.com/CryptoDiffer",
      avatar: "https://pbs.twimg.com/profile_images/1669338887385419777/kgGu-9Ad_400x400.jpg"
    },
    {
      id: 5,
      content: "Working with KOL Capital has been fantastic. Their team consistently brings top-notch deals and provides exceptional support. The strong relationships and high-quality opportunities they offer have significantly boosted my success. I highly recommend KOL Capital for any KOL looking to access exclusive, valuable deals.",
      author: "DCI",
      link: "https://twitter.com/dci_crypto",
      avatar: "https://pbs.twimg.com/profile_images/1008791985556246529/pgu7m2CD_400x400.jpg"
    },
    {
      id: 6,
      content: "My experience with KOL Capital has been great so far. I joined just a few months ago and was able to invest in two projects only for now (Mystiko and Earnm). And I’m super happy for both. The whole experience is great, the quality of deals in my opinion is way above of the ones offered in other KOLs syndicates. And also the whole support and management during promotional phase is smooth and on point. So yes, I’m pleased to be part of KOL Capital, to give my contribution to its future growth and success.",
      author: "Romeo Trades",
      link: "https://twitter.com/RomeoTrades",
      avatar: "https://pbs.twimg.com/profile_images/1759901821823307777/ir-3_CyP_400x400.jpg"
    },
    {
      id: 7,
      content: "KOL Capital has done well so far, considering it's a new VC. There have been a few bumps along the road but that is to be expected in crypto.",
      author: "JoshWorld",
      link: "https://www.youtube.com/CryptoWorldJosh",
      avatar: "https://yt3.googleusercontent.com/BDIVdJouel5oIcamH_vSYVS2iDRaX3xD8l5g5TFd7MS-avfxLEWbbJtGL9j-G2WLVFF3YZU0nQ=s160-c-k-c0x00ffffff-no-rj"
    },
    {
      id: 8,
      content: "Kol Capital consists of a highly qualified and successful team. Trust is paramount in this kind of business and I trust you. That's why I can cooperate with the quality projects you collaborate with with peace of mind.",
      author: "Krypto Kahin",
      link: "https://x.com/kahincryptocu",
      avatar: "https://pbs.twimg.com/profile_images/1539735264591069185/chjxIpq3_400x400.jpg"
    }
  ];

  export  const testimonialsprojects = [
    {
      id: 9,
      content: "The KOLs are active and help us promote the narratives and some have exceptionally worked hard to convey the narrative in their own style The KOL capital has helped us amplify the reach of our campaigns to get more traction",
      author: "KelpDAO",
      link: "https://x.com/KelpDAO",
      avatar: "https://pbs.twimg.com/profile_images/1719718670610427904/IwyLpOfL_400x400.jpg"
    },
    {
      id: 10,
      content: "KOL Capital is a supportive investment partner for Minterest, with a driven team that consistently adds value through their patience, organization, respect, and responsiveness—qualities that are uncommon in the Web3 space. They understand the market pulse and provide valuable insights that support our needs. Additionally, their unique social media presence has significantly amplified our messages to a much broader audience. We appreciate their work and believe any project seeking a mix of capital injection, awareness support, and strategic advice will find an excellent partner in KOL Capital.",
      author: "Minterest",
      link: "https://x.com/Minterest",
      avatar: "https://pbs.twimg.com/profile_images/1707306545065705472/b8ARCDsg_400x400.jpg"
    },
    {
      id: 11,
      content: "We raised nearly $500,000 with over 100 KOLs participating from KOL Capital for EARNM! We are very happy with the support from KOL Capital and the Team. The team was quick to execute and has been a great partner to have on your side. We highly recommend working with them",
      author: "EarnM",
      link: "https://x.com/EARNMrewards",
      avatar: "https://pbs.twimg.com/profile_images/1522230581802590209/_8-IZbx-_400x400.jpg"
    },
    {
      id: 12,
      content: "KOL Capital brings together a network of experts who are hands-on, participating in your protocol and sharing its value propositions with their audiences, thereby increasing the adoption of the technology and its use cases. KOL Capital helped us partner with over 200 KOLs who contributed over $1MM in value to the protocol including some of the biggest leaders in the space.",
      author: "Partisia",
      link: "https://x.com/partisiampc",
      avatar: "https://pbs.twimg.com/profile_images/1723684472636190720/O4a1ZT4N_400x400.jpg"
    },
    {
      id: 13,
      content: "Working with KOL Capital as our early investors has been incredibly beneficial. Their support, promotion, and strategic guidance have significantly fueled our growth over the years. Their industry knowledge and network have been invaluable, helping us overcome challenges and seize opportunities. We're grateful for their partnership and look forward to continued success together.",
      author: "Magic Square",
      link: "https://x.com/MagicSquareio",
      avatar: "https://pbs.twimg.com/profile_images/1609890739630080000/GINHYFc5_400x400.png"
    }
  ];

  export const kolslist = [
    {
      name: "MoonCarl",
      title: "1,300,000",
      imageSrc: bgkol1,
      socialLinks: [
        { platform: 'twitter', url: 'https://x.com/TheMoonCarl' },
      ]
    },
    {
      "name": "Andres Meneses",
      "title": "3,000,000",
      "imageSrc": bgkol35,
      "socialLinks": [
        { "platform": "instagram", "url": "https://instagram.com/andreswifitv" }
      ]
    },
    {
      name: "Crypto Rover",
      title: "728,000",
      imageSrc: bgkol2,
      socialLinks: [
        { platform: 'twitter', url: 'https://x.com/rovercrc' },
      ]
    },
    {
      name: "Bycoinhunter",
      title: "505,000",
      imageSrc: bgkol3,
      socialLinks: [
        { platform: 'twitter', url: 'https://x.com/bycoinhunter' },
      ]
    },
    {
      name: "Wallstreet bnb",
      title: "495,000",
      imageSrc: bgkol4,
      socialLinks: [
        { platform: 'twitter', url: 'https://x.com/Wallstreet_bnb' },
      ]
    },
    {
      name: "Satoshi Stacker",
      title: "200,600",
      imageSrc: bgkol5,
      socialLinks: [
        { platform: 'twitter', url: 'https://x.com/StackerSatoshi' },
      ]
    },
    {
      name: "Coach K",
      title: "200,000",
      imageSrc: bgkol6,
      socialLinks: [
        { platform: 'telegram', url: 'https://t.me/coachkcrypto' },
      ]
    },
    {
      name: "Dusty",
      title: "154,000",
      imageSrc: bgkol7,
      socialLinks: [
        { platform: 'twitter', url: 'https://x.com/TheDustyBC' },
      ]
    },
    {
      name: "Mocho",
      title: "143,000",
      imageSrc: bgkol8,
      socialLinks: [
        { platform: 'twitter', url: 'https://x.com/cryptomocho' },
      ]
    },
    {
      name: "Brian Rose",
      title: "216,900",
      imageSrc: bgkol9,
      socialLinks: [
        { platform: 'twitter', url: 'https://x.com/LondonRealTV' },
      ]
    },
    {
      "name": "Alaa",
      "title": "300,000",
      "imageSrc": bgkol11,
      "socialLinks": [
          { "platform": "twitter", "url": "https://x.com/alshami_crypto" }
      ]
  },
  {
    "name": "Adrian Golub",
    "title": "188,000",
    "imageSrc": bgkol16,
    "socialLinks": [
        { "platform": "instagram", "url": "https://www.instagram.com/adrian_golub/?hl=es-la" }
    ]
},
    {
      "name": "IDO Presale Insights",
      "title": "561,700",
      "imageSrc": bgkol36,
      "socialLinks": [
          { "platform": "twitter", "url": "https://x.com/IDO_Insights" }
      ]
    },  
    {
      "name": "IBCIG",
      "title": "549,000",
      "imageSrc": bgkol10,
      "socialLinks": [
          { "platform": "twitter", "url": "https://www.x.com/ibcig" }
      ]
  },
  {
    "name": "Wise Crypto",
    "title": "363,500",
    "imageSrc": bgkol37,
    "socialLinks": [
        { "platform": "twitter", "url": "https://twitter.com/WiseCrypto_" }
    ]
  },
  {
      "name": "Crypto Differ",
      "title": "280,000",
      "imageSrc": bgkol12,
      "socialLinks": [
          { "platform": "twitter", "url": "https://twitter.com/CryptoDiffer" }
      ]
  },
  {
    "name": "Lion ETH",
    "title": "250,000",
    "imageSrc": bgkol38,
    "socialLinks": [
        { "platform": "twitter", "url": "https://twitter.com/LionETH_" }
    ]
  },
  {
      "name": "Futur",
      "title": "250,000",
      "imageSrc": bgkol13,
      "socialLinks": [
          { "platform": "twitter", "url": "https://x.com/crypto_futur?s=21&t=gqzwyCB2JTzYIYEqpn6wog" }
      ]
  },
  {
      "name": "Tekinikci Hoca",
      "title": "226,500",
      "imageSrc": bgkol14,
      "socialLinks": [
          { "platform": "twitter", "url": "https://x.com/teknikcihoca" }
      ]
  },
  {
      "name": "Crypto Revolution Masters",
      "title": "200,000",
      "imageSrc": bgkol15,
      "socialLinks": [
          { "platform": "twitter", "url": "https://x.com/revolut20?t=J9cbOhGcfY0IPQkM29ZRVw&s=09" }
      ]
  },
  {
      "name": "Crypto Zoro",
      "title": "179,300",
      "imageSrc": bgkol17,
      "socialLinks": [
          { "platform": "twitter", "url": "https://twitter.com/0xRoronoaZoro" }
      ]
  },
  {
      "name": "Top7ICO",
      "title": "172,600",
      "imageSrc": bgkol18,
      "socialLinks": [
          { "platform": "twitter", "url": "https://twitter.com/top7ico" }
      ]
  },
  {
      "name": "Inac Salman",
      "title": "165,300",
      "imageSrc": bgkol19,
      "socialLinks": [
          { "platform": "twitter", "url": "https://x.com/inancsalman" }
      ]
  },
  {
      "name": "Xeus",
      "title": "140,600",
      "imageSrc": bgkol20,
      "socialLinks": [
          { "platform": "twitter", "url": "https://x.com/SamuelXeus" }
      ]
  },
  {
      "name": "Q Ksa",
      "title": "136,600",
      "imageSrc": bgkol21,
      "socialLinks": [
          { "platform": "twitter", "url": "https://x.com/Crypto_K_S_A" }
      ]
  },
  {
      "name": "Mooby Media",
      "title": "123,200",
      "imageSrc": bgkol22,
      "socialLinks": [
          { "platform": "twitter", "url": "https://x.com/mobymedia" }
      ]
  },
  {
      "name": "Rob Hanneman",
      "title": "115,200",
      "imageSrc": bgkol23,
      "socialLinks": [
          { "platform": "twitter", "url": "https://x.com/robhanneman0" }
      ]
  },
  {
      "name": "Toori",
      "title": "110,000",
      "imageSrc": bgkol24,
      "socialLinks": [
          { "platform": "twitter", "url": "https://x.com/TooriAlpha" }
      ]
  },
  {
      "name": "Axel Blaze",
      "title": "109,000",
      "imageSrc": bgkol25,
      "socialLinks": [
          { "platform": "twitter", "url": "https://x.com/axel_bitblaze69" }
      ]
  },
  {
      "name": "Crypto Dep",
      "title": "107,000",
      "imageSrc": bgkol26,
      "socialLinks": [
          { "platform": "twitter", "url": "https://x.com/Crypto_Dep" }
      ]
  },
  {
      "name": "Genel Patron",
      "title": "100,500",
      "imageSrc": bgkol27,
      "socialLinks": [
          { "platform": "twitter", "url": "https://x.com/genelpatron01" }
      ]
  },
  {
      "name": "Sorunsa ElliTon",
      "title": "94,700",
      "imageSrc": bgkol28,
      "socialLinks": [
          { "platform": "twitter", "url": "https://x.com/SorunsalElliTon" }
      ]
  },
  {
      "name": "Kripto Kahin",
      "title": "85,100",
      "imageSrc": bgkol29,
      "socialLinks": [
          { "platform": "twitter", "url": "https://x.com/kahincryptocu" }
      ]
  },
  {
    "name": "Bitmonk",
    "title": "85,000",
    "imageSrc": bgkol39,
    "socialLinks": [
        { "platform": "twitter", "url": "https://x.com/BitmonkCrypto" }
    ]
  },
  {
    "name": "Prudent Sammy",
    "title": "84,000",
    "imageSrc": bgkol40,
    "socialLinks": [
        { "platform": "twitter", "url": "https://x.com/PrudentSammy" }
    ]
  },
  {
      "name": "Romeo Trades",
      "title": "74,000",
      "imageSrc": bgkol30,
      "socialLinks": [
          { "platform": "twitter", "url": "https://x.com/RomeoTrades" }
      ]
  },
  {
    "name": "Domen",
    "title": "59,000",
    "imageSrc": bgkol41,
    "socialLinks": [
        { "platform": "twitter", "url": "https://x.com/DomOnChain" }
    ]
  },
  {
      "name": "Sheriff",
      "title": "50,000",
      "imageSrc": bgkol31,
      "socialLinks": [
          { "platform": "twitter", "url": "https://x.com/DaSheriffCrypto" }
      ]
  },
  {
    "name": "Block Talks",
    "title": "47,400",
    "imageSrc": bgkol42,
    "socialLinks": [
        { "platform": "twitter", "url": "https://x.com/HiBlockTalks" }
    ]
  },
  {
    "name": "IVANOV",
    "title": "47,100",
    "imageSrc": bgkol43,
    "socialLinks": [
        { "platform": "twitter", "url": "https://x.com/cryptoivanovx" }
    ]
  },
  {
    "name": "Hubert",
    "title": "43,100",
    "imageSrc": bgkol44,
    "socialLinks": [
        { "platform": "twitter", "url": "https://x.com/TradingHubert" }
    ]
  },
  {
      "name": "Cryptourist",
      "title": "41,900",
      "imageSrc": bgkol32,
      "socialLinks": [
          { "platform": "twitter", "url": "https://twitter.com/cryp_tourist" }
      ]
  },
  {
      "name": "The Dude",
      "title": "30,600",
      "imageSrc": bgkol33,
      "socialLinks": [
          { "platform": "twitter", "url": "https://x.com/thedude0ne" }
      ]
  },
    {
      "name": "Nonced",
      "title": "35,400",
      "imageSrc": bgkol45,
      "socialLinks": [
        { "platform": "twitter", "url": "https://twitter.com/nonced_" }
      ]
    },
    {
      name: "0x0zi",
      title: "27,300",
      imageSrc: bgkol34,
      socialLinks: [
          { "platform": "twitter", "url": "https://x.com/0xOziK" }
      ]
    },
    {
      "name": "Uncle Marc",
      "title": "25,100",
      "imageSrc": bgkol46,
      "socialLinks": [
        { "platform": "twitter", "url": "https://x.com/Marc_LFG" }
      ]
    },
    {
      "name": "Crypto Nation",
      "title": "25,000",
      "imageSrc": bgkol47,
      "socialLinks": [
        { "platform": "twitter", "url": "https://x.com/CryptoNationN" }
      ]
    },
    {
      "name": "Swiat Crypto",
      "title": "22,600",
      "imageSrc": bgkol48,
      "socialLinks": [
        { "platform": "twitter", "url": "https://twitter.com/SwiatKrypto" }
      ]
    },
    {
      "name": "Chain Broker",
      "title": "22,500",
      "imageSrc": bgkol49,
      "socialLinks": [
        { "platform": "twitter", "url": "https://twitter.com/chain_broker" }
      ]
    },
    {
      "name": "Crypto Kuii",
      "title": "22,100",
      "imageSrc": bgkol50,
      "socialLinks": [
        { "platform": "twitter", "url": "https://twitter.com/CryptoKuiil" }
      ]
    },
    {
      "name": "Prestito",
      "title": "22,100",
      "imageSrc": bgkol51,
      "socialLinks": [
        { "platform": "youtube", "url": "https://www.youtube.com/@PrestitoCrypto" }
      ]
    },
    {
      "name": "Fomo Catcher",
      "title": "20,500",
      "imageSrc": bgkol52,
      "socialLinks": [
        { "platform": "twitter", "url": "https://x.com/FomoCatchers" }
      ]
    },
    {
      "name": "AshxGems",
      "title": "14,500",
      "imageSrc": bgkol53,
      "socialLinks": [
        { "platform": "twitter", "url": "https://x.com/ashxgemsss" }
      ]
    },
    {
      "name": "Moon Walker",
      "title": "14,190",
      "imageSrc": bgkol54,
      "socialLinks": [
        { "platform": "telegram", "url": "https://t.me/tehMoonwalkeRs" }
      ]
    },
    {
      "name": "Vlad Stoica",
      "title": "4,668",
      "imageSrc": bgkol55,
      "socialLinks": [
        { "platform": "twitter", "url": "https://x.com/vladstoica23" }
      ]
    },
    {
      "name": "Raares",
      "title": "81,000",
      "imageSrc": bgkol56,
      "socialLinks": [
        { "platform": "twitter", "url": "https://x.com/RaAr3s" }
      ]
    },
    {
      "name": "Mesgo",
      "title": "31,300",
      "imageSrc": bgkol57,
      "socialLinks": [
        { "platform": "twitter", "url": "https://twitter.com/mesgo_" }
      ]
    },
    {
      "name": "SkyLine",
      "title": "30,000",
      "imageSrc": bgkol58,
      "socialLinks": [
        { "platform": "twitter", "url": "https://twitter.com/skylineeth" }
      ]
    },
    {
      "name": "Ajith",
      "title": "29,900",
      "imageSrc": bgkol59,
      "socialLinks": [
        { "platform": "youtube", "url": "https://www.youtube.com/channel/UCxPH_9TZEWJwCueIaT1pd3A" }
      ]
    },
    {
      "name": "Pablo Escobar",
      "title": "28,400",
      "imageSrc": bgkol60,
      "socialLinks": [
        { "platform": "twitter", "url": "https://x.com/Escobar_Vision" }
      ]
    },
    {
      "name": "Kadir",
      "title": "27,700",
      "imageSrc": bgkol61,
      "socialLinks": [
        { "platform": "twitter", "url": "https://x.com/Kadiralar20" }
      ]
    },
    {
      "name": "Tech Fanatic",
      "title": "27,000",
      "imageSrc": bgkol64,
      "socialLinks": [
        { "platform": "youtube", "url": "https://www.youtube.com/c/Techfanaticofficial" }
      ]
    },
    {
      "name": "Cristu",
      "title": "15,000",
      "imageSrc": bgkol63,
      "socialLinks": [
        { "platform": "twitter", "url": "https://x.com/Cristu_eth" }
      ]
    },
    {
      "name": "Phoenix",
      "title": "25,000",
      "imageSrc": bgkol65,
      "socialLinks": [
        { "platform": "twitter", "url": "https://twitter.com/pnxgrp" }
      ]
    },
    {
      "name": "Caio Villa",
      "title": "10,000",
      "imageSrc": bgkol62,
      "socialLinks": [
        { "platform": "twitter", "url": "https://twitter.com/CriptoVilla" }
      ]
    },
    {
      "name": "Hrithik",
      "title": "6,883",
      "imageSrc": bgkol66,
      "socialLinks": [
        { "platform": "twitter", "url": "https://x.com/dude_its_ritik" }
      ]
    },
      {
        "name": "Online Pesa",
        "title": "190,000",
        "imageSrc": bgkol67,
        "socialLinks": [
          { "platform": "twitter", "url": "https://twitter.com/OnlinePesaYT" }
        ]
      },
      {
        "name": "Gem Buyer",
        "title": "75,000",
        "imageSrc": bgkol68,
        "socialLinks": [
          { "platform": "twitter", "url": "https://x.com/Gem_Buyer" }
        ]
      },
      {
        "name": "Altcoin Alerts",
        "title": "27,500",
        "imageSrc": bgkol69,
        "socialLinks": [
          { "platform": "twitter", "url": "https://twitter.com/Altcoin_Alerts" }
        ]
      },
      {
        "name": "Crypto Legend",
        "title": "18,000",
        "imageSrc": bgkol70,
        "socialLinks": [
          { "platform": "twitter", "url": "https://twitter.com/cryptolegend81" }
        ]
      },
      {
        "name": "Josh Bored",
        "title": "17,500",
        "imageSrc": bgkol71,
        "socialLinks": [
          { "platform": "twitter", "url": "https://x.com/JoshhBored" }
        ]
      },
      {
        "name": "Lady Trader Ra",
        "title": "100,000",
        "imageSrc": bgkol72,
        "socialLinks": [
          { "platform": "twitter", "url": "https://x.com/LadyTraderRa" }
        ]
      },
      {
        "name": "Ri OG",
        "title": "19,000",
        "imageSrc": bgkol73,
        "socialLinks": [
          { "platform": "twitter", "url": "https://twitter.com/RitheOG" }
        ]
      },
      {
        "name": "Murana",
        "title": "18,000",
        "imageSrc": bgkol74,
        "socialLinks": [
          { "platform": "twitter", "url": "https://x.com/murana_eth" }
        ]
      },
      {
        "name": "Crypto Daku",
        "title": "203,000",
        "imageSrc": bgkol75,
        "socialLinks": [
          { "platform": "twitter", "url": "https://x.com/CryptoDaku_" }
        ]
      },
      {
        "name": "Lambo Chad",
        "title": "18,000",
        "imageSrc": bgkol76,
        "socialLinks": [
          { "platform": "twitter", "url": "https://x.com/LamboChads" }
        ]
      },
      {
        "name": "ᴋɪʟɪÇᴀsʟᴀɴ",
        "title": "19,000",
        "imageSrc": bgkol77,
        "socialLinks": [
          { "platform": "twitter", "url": "https://x.com/Kilicaslan_tr" }
        ]
      },
      {
        "name": "Josh",
        "title": "57,000",
        "imageSrc": bgkol78,
        "socialLinks": [
          { "platform": "twitter", "url": "https://x.com/CryptoWorldJosh" }
        ]
      },
      {
        "name": "Crypto Viet Girl",
        "title": "13,000",
        "imageSrc": bgkol79,
        "socialLinks": [
          { "platform": "twitter", "url": "https://x.com/crptvietgirl" }
        ]
      },
      {
        "name": "Show Maker",
        "title": "97,000",
        "imageSrc": bgkol80,
        "socialLinks": [
          { "platform": "twitter", "url": "https://x.com/0xshowmaker" }
        ]
      },
      {
        "name": "Crypto Verse",
        "title": "85,000",
        "imageSrc": bgkol81,
        "socialLinks": [
          { "platform": "twitter", "url": "https://x.com/CryptoXVerse" }
        ]
      },
      {
        "name": "Kevena",
        "title": "240,000",
        "imageSrc": bgkol82,
        "socialLinks": [
          { "platform": "twitter", "url": "https://x.com/DevveSquad100x" }
        ]
      },
      {
        "name": "Roger",
        "title": "15,000",
        "imageSrc": bgkol83,
        "socialLinks": [
          { "platform": "twitter", "url": "https://x.com/RogerCrypto_" }
        ]
      },
      {
        "name": "Sam Huber",
        "title": "13,000",
        "imageSrc": bgkol84,
        "socialLinks": [
          { "platform": "twitter", "url": "https://x.com/SamHuber" }
        ]
      },
      {
        "name": "Bitprop",
        "title": "32,000",
        "imageSrc": bgkol85,
        "socialLinks": [
          { "platform": "twitter", "url": "https://x.com/CryptoBitcoin88" }
        ]
      },
      {
        "name": "Mystery of Crypto",
        "title": "350,000",
        "imageSrc": bgkol86,
        "socialLinks": [
          { "platform": "twitter", "url": "https://x.com/Mysteryofcrypt" }
        ]
      },
      {
        "name": "Samurai Crypto",
        "title": "105,000",
        "imageSrc": bgkol87,
        "socialLinks": [
          { "platform": "twitter", "url": "https://twitter.com/samurai_cz" }
        ]
      },
      {
        "name": "Perecrypto",
        "title": "324,000",
        "imageSrc": bgkol88,
        "socialLinks": [
          { "platform": "instagram", "url": "https://www.instagram.com/perecrypto/" }
        ]
      },
      {
        "name": "Cryptocurry",
        "title": "18,000",
        "imageSrc": bgkol89,
        "socialLinks": [
          { "platform": "twitter", "url": "https://twitter.com/BhaktOfAltcoins" }
        ]
      }    
  ]

  export const globalorderlist = [
    {
      name: "MoonCarl",
      title: "1,300,000",
      imageSrc: bgkol1,
      socialLinks: [
        { platform: 'twitter', url: 'https://x.com/TheMoonCarl' },
      ]
    },
    {
      "name": "Andres Meneses",
      "title": "3,000,000",
      "imageSrc": bgkol35,
      "socialLinks": [
        { "platform": "instagram", "url": "https://instagram.com/andreswifitv" }
      ]
    },
    {
      name: "Crypto Rover",
      title: "728,000",
      imageSrc: bgkol2,
      socialLinks: [
        { platform: 'twitter', url: 'https://x.com/rovercrc' },
      ]
    },
    {
      name: "Bycoinhunter",
      title: "505,000",
      imageSrc: bgkol3,
      socialLinks: [
        { platform: 'twitter', url: 'https://x.com/bycoinhunter' },
      ]
    },
    {
      name: "Wallstreet bnb",
      title: "495,000",
      imageSrc: bgkol4,
      socialLinks: [
        { platform: 'twitter', url: 'https://x.com/Wallstreet_bnb' },
      ]
    },
    {
      name: "Satoshi Stacker",
      title: "200,600",
      imageSrc: bgkol5,
      socialLinks: [
        { platform: 'twitter', url: 'https://x.com/StackerSatoshi' },
      ]
    },
    {
      name: "Coach K",
      title: "200,000",
      imageSrc: bgkol6,
      socialLinks: [
        { platform: 'telegram', url: 'https://t.me/coachkcrypto' },
      ]
    },
    {
      name: "Dusty",
      title: "154,000",
      imageSrc: bgkol7,
      socialLinks: [
        { platform: 'twitter', url: 'https://x.com/TheDustyBC' },
      ]
    },
    {
      name: "Mocho",
      title: "143,000",
      imageSrc: bgkol8,
      socialLinks: [
        { platform: 'twitter', url: 'https://x.com/cryptomocho' },
      ]
    },
    {
      name: "Brian Rose",
      title: "216,900",
      imageSrc: bgkol9,
      socialLinks: [
        { platform: 'twitter', url: 'https://x.com/LondonRealTV' },
      ]
    },
    {
      "name": "Alaa",
      "title": "300,000",
      "imageSrc": bgkol11,
      "socialLinks": [
        { "platform": "twitter", "url": "https://x.com/alshami_crypto" }
      ]
    },
    {
      "name": "Adrian Golub",
      "title": "188,000",
      "imageSrc": bgkol16,
      "socialLinks": [
        { "platform": "instagram", "url": "https://www.instagram.com/adrian_golub/?hl=es-la" }
      ]
    },
    {
      "name": "Inac Salman",
      "title": "165,300",
      "imageSrc": bgkol19,
      "socialLinks": [
        { "platform": "twitter", "url": "https://x.com/inancsalman" }
      ]
    },
    {
      "name": "Rob Hanneman",
      "title": "115,200",
      "imageSrc": bgkol23,
      "socialLinks": [
        { "platform": "twitter", "url": "https://x.com/robhanneman0" }
      ]
    },
    {
      "name": "Axel Blaze",
      "title": "109,000",
      "imageSrc": bgkol25,
      "socialLinks": [
        { "platform": "twitter", "url": "https://x.com/axel_bitblaze69" }
      ]
    },
    {
      "name": "Sorunsa ElliTon",
      "title": "94,700",
      "imageSrc": bgkol28,
      "socialLinks": [
        { "platform": "twitter", "url": "https://x.com/SorunsalElliTon" }
      ]
    },
    {
      "name": "Romeo Trades",
      "title": "74,000",
      "imageSrc": bgkol30,
      "socialLinks": [
        { "platform": "twitter", "url": "https://x.com/RomeoTrades" }
      ]
    },
    {
      "name": "Domen",
      "title": "59,700",
      "imageSrc": bgkol41,
      "socialLinks": [
        { "platform": "twitter", "url": "https://twitter.com/DomOnChain" }
      ]
    },
    {
      "name": "Sheriff",
      "title": "50,000",
      "imageSrc": bgkol31,
      "socialLinks": [
        { "platform": "twitter", "url": "https://x.com/DaSheriffCrypto" }
      ]
    },
    {
      "name": "Sam Huber",
      "title": "13,000",
      "imageSrc": bgkol84,
      "socialLinks": [
        { "platform": "twitter", "url": "https://x.com/SamHuber" }
      ]
    },
    {
      "name": "Cristu",
      "title": "15,000",
      "imageSrc": bgkol63,
      "socialLinks": [
        { "platform": "twitter", "url": "https://x.com/Cristu_eth" }
      ]
    },
    {
      "name": "Perecrypto",
      "title": "324,000",
      "imageSrc": bgkol88,
      "socialLinks": [
        { "platform": "instagram", "url": "https://www.instagram.com/perecrypto/" }
      ]
    },
    {
      "name": "Raares",
      "title": "81,000",
      "imageSrc": bgkol56,
      "socialLinks": [
        { "platform": "twitter", "url": "https://x.com/RaAr3s" }
      ]
    },
    {
      "name": "Caio Villa",
      "title": "10,000",
      "imageSrc": bgkol62,
      "socialLinks": [
        { "platform": "twitter", "url": "https://twitter.com/CriptoVilla" }
      ]
    },
    {
      "name": "Cryptocurry",
      "title": "18,000",
      "imageSrc": bgkol89,
      "socialLinks": [
        { "platform": "twitter", "url": "https://twitter.com/BhaktOfAltcoins" }
      ]
    },
    {
      "name": "Dan Fuentes",
      "title": "1,300,000",
      "imageSrc": bgkol90,
      "socialLinks": [
        { "platform": "youtube", "url": "https://www.youtube.com/@dan_fuentes/videos" }
      ]
    },
    {
      "name": "Vlad Stoica",
      "title": "4,668",
      "imageSrc": bgkol55,
      "socialLinks": [
        { "platform": "twitter", "url": "https://x.com/vladstoica23" }
      ]
    },
    {
      "name": "Uncle Marc",
      "title": "25,100",
      "imageSrc": bgkol46,
      "socialLinks": [
        { "platform": "twitter", "url": "https://x.com/Marc_LFG" }
      ]
    },
    {
      "name": "Crypto Daku",
      "title": "203,000",
      "imageSrc": bgkol75,
      "socialLinks": [
        { "platform": "twitter", "url": "https://x.com/CryptoDaku_" }
      ]
    },
    {
      "name": "Pablo Escobar",
      "title": "28,400",
      "imageSrc": bgkol60,
      "socialLinks": [
        { "platform": "twitter", "url": "https://x.com/Escobar_Vision" }
      ]
    },
    {
      "name": "Josh Bored",
      "title": "17,500",
      "imageSrc": bgkol71,
      "socialLinks": [
        { "platform": "twitter", "url": "https://x.com/JoshhBored" }
      ]
    },
    {
      "name": "Crypto Differ",
      "title": "280,000",
      "imageSrc": bgkol12,
      "socialLinks": [
        { "platform": "twitter", "url": "https://twitter.com/CryptoDiffer" }
      ]
    },
    {
      "name": "Crypto Dep",
      "title": "107,000",
      "imageSrc": bgkol26,
      "socialLinks": [
        { "platform": "twitter", "url": "https://x.com/Crypto_Dep" }
      ]
    },
    {
      "name": "Top7ICO",
      "title": "172,600",
      "imageSrc": bgkol18,
      "socialLinks": [
        { "platform": "twitter", "url": "https://twitter.com/top7ico" }
      ]
    },
    {
      "name": "Toori",
      "title": "110,000",
      "imageSrc": bgkol24,
      "socialLinks": [
        { "platform": "twitter", "url": "https://twitter.com/TooriAlpha" }
      ]
    },
    {
      "name": "Genel Patron",
      "title": "100,500",
      "imageSrc": bgkol27,
      "socialLinks": [
        { "platform": "twitter", "url": "https://twitter.com/genelpatron01" }
      ]
    },
    {
      "name": "Kripto Kahin",
      "title": "85,100",
      "imageSrc": bgkol29,
      "socialLinks": [
        { "platform": "twitter", "url": "https://x.com/kahincryptocu" }
      ]
    },
    {
      "name": "Josh",
      "title": "57,000",
      "imageSrc": bgkol78,
      "socialLinks": [
        { "platform": "twitter", "url": "https://x.com/CryptoWorldJosh" }
      ]
    },
    {
      "name": "Bitmonk",
      "title": "85,000",
      "imageSrc": bgkol39,
      "socialLinks": [
        { "platform": "twitter", "url": "https://twitter.com/BitmonkCrypto" }
      ]
    },
    {
      "name": "Mesgo",
      "title": "31,300",
      "imageSrc": bgkol57,
      "socialLinks": [
        { "platform": "twitter", "url": "https://twitter.com/mesgo_" }
      ]
    },
    {
      "name": "SkyLine",
      "title": "30,000",
      "imageSrc": bgkol58,
      "socialLinks": [
        { "platform": "twitter", "url": "https://twitter.com/skylineeth" }
      ]
    },
    {
      "name": "Hrithik",
      "title": "6,883",
      "imageSrc": bgkol66,
      "socialLinks": [
        { "platform": "twitter", "url": "https://x.com/dude_its_ritik" }
      ]
    },
    {
      "name": "Bitprop",
      "title": "32,000",
      "imageSrc": bgkol85,
      "socialLinks": [
        { "platform": "twitter", "url": "https://x.com/CryptoBitcoin88" }
      ]
    },
    {
      "name": "Lady Trader Ra",
      "title": "100,000",
      "imageSrc": bgkol72,
      "socialLinks": [
        { "platform": "twitter", "url": "https://x.com/LadyTraderRa" }
      ]
    },
    {
      "name": "Ri OG",
      "title": "19,000",
      "imageSrc": bgkol73,
      "socialLinks": [
        { "platform": "twitter", "url": "https://twitter.com/RitheOG" }
      ]
    },
    {
      "name": "Murana",
      "title": "18,000",
      "imageSrc": bgkol74,
      "socialLinks": [
        { "platform": "twitter", "url": "https://twitter.com/murana_eth" }
      ]
    },
    {
      "name": "Lion ETH",
      "title": "250,000",
      "imageSrc": bgkol38,
      "socialLinks": [
        { "platform": "twitter", "url": "https://twitter.com/LionETH_" }
      ]
    },
    {
      "name": "IBCIG",
      "title": "549,000",
      "imageSrc": bgkol10,
      "socialLinks": [
        { "platform": "twitter", "url": "https://www.x.com/ibcig" }
      ]
    },
    {
      "name": "IDO Presale Insights",
      "title": "561,700",
      "imageSrc": bgkol36,
      "socialLinks": [
        { "platform": "twitter", "url": "https://x.com/IDO_Insights" }
      ]
    },
    {
      "name": "Xeus",
      "title": "140,600",
      "imageSrc": bgkol20,
      "socialLinks": [
        { "platform": "twitter", "url": "https://twitter.com/SamuelXeus" }
      ]
    },
    {
      "name": "Wise Crypto",
      "title": "363,500",
      "imageSrc": bgkol37,
      "socialLinks": [
        { "platform": "twitter", "url": "https://twitter.com/WiseCrypto_" }
      ]
    },
    {
      "name": "Futur",
      "title": "250,000",
      "imageSrc": bgkol13,
      "socialLinks": [
        { "platform": "twitter", "url": "https://x.com/crypto_futur?s=21&t=gqzwyCB2JTzYIYEqpn6wog" }
      ]
    },
    {
      "name": "Crypto Revolution Masters",
      "title": "200,000",
      "imageSrc": bgkol15,
      "socialLinks": [
        { "platform": "twitter", "url": "https://x.com/revolut20?t=J9cbOhGcfY0IPQkM29ZRVw&s=09" }
      ]
    },
    {
      "name": "Tekinikci Hoca",
      "title": "226,500",
      "imageSrc": bgkol14,
      "socialLinks": [
        { "platform": "twitter", "url": "https://x.com/teknikcihoca" }
      ]
    },
    {
      "name": "Online Pesa",
      "title": "190,000",
      "imageSrc": bgkol67,
      "socialLinks": [
        { "platform": "twitter", "url": "https://twitter.com/OnlinePesaYT" }
      ]
    },
    {
      "name": "Crypto Zoro",
      "title": "179,300",
      "imageSrc": bgkol17,
      "socialLinks": [
        { "platform": "twitter", "url": "https://twitter.com/0xRoronoaZoro" }
      ]
    },
    {
      "name": "The Crypto Blade",
      "title": "74,800",
      "imageSrc": bgkol91,
      "socialLinks": [
        { "platform": "twitter", "url": "https://twitter.com/TheCryptoBlade" }
      ]
    },
    {
      "name": "Q Ksa",
      "title": "136,600",
      "imageSrc": bgkol21,
      "socialLinks": [
        { "platform": "twitter", "url": "https://x.com/Crypto_K_S_A" }
      ]
    },
    {
      "name": "Moby Media",
      "title": "123,200",
      "imageSrc": bgkol22,
      "socialLinks": [
        { "platform": "twitter", "url": "https://twitter.com/mobymedia" }
      ]
    },
    {
      "name": "Block Talks",
      "title": "47,400",
      "imageSrc": bgkol42,
      "socialLinks": [
        { "platform": "twitter", "url": "https://twitter.com/HiBlockTalks" }
      ]
    },
    {
      "name": "Prudent Sammy",
      "title": "84,000",
      "imageSrc": bgkol40,
      "socialLinks": [
        { "platform": "twitter", "url": "https://x.com/PrudentSammy" }
      ]
    },
    {
      "name": "Gem Buyer",
      "title": "75,000",
      "imageSrc": bgkol68,
      "socialLinks": [
        { "platform": "twitter", "url": "https://x.com/Gem_Buyer" }
      ]
    },
    {
      "name": "IVANOV",
      "title": "47,100",
      "imageSrc": bgkol43,
      "socialLinks": [
        { "platform": "twitter", "url": "https://x.com/cryptoivanovx" }
      ]
    },
    {
      "name": "Hubert",
      "title": "43,100",
      "imageSrc": bgkol44,
      "socialLinks": [
        { "platform": "twitter", "url": "https://x.com/TradingHubert" }
      ]
    },
    {
      "name": "Cryptourist",
      "title": "41,900",
      "imageSrc": bgkol32,
      "socialLinks": [
        { "platform": "twitter", "url": "https://twitter.com/cryp_tourist" }
      ]
    },
    {
      "name": "Nonced",
      "title": "35,400",
      "imageSrc": bgkol45,
      "socialLinks": [
        { "platform": "twitter", "url": "https://twitter.com/nonced_" }
      ]
    },
    {
      "name": "The Dude",
      "title": "30,600",
      "imageSrc": bgkol33,
      "socialLinks": [
        { "platform": "twitter", "url": "https://x.com/thedude0ne" }
      ]
    },
    {
      "name": "Dus Fi",
      "title": "35,000",
      "imageSrc": bgkol92,
      "socialLinks": [
        { "platform": "twitter", "url": "https://twitter.com/dus_fi" }
      ]
    },
    {
      "name": "0x0zi",
      "title": "27,300",
      "imageSrc": bgkol34,
      "socialLinks": [
        { "platform": "twitter", "url": "https://x.com/0xOziK" }
      ]
    },
    {
      "name": "Crypto Nation",
      "title": "25,000",
      "imageSrc": bgkol47,
      "socialLinks": [
        { "platform": "twitter", "url": "https://x.com/CryptoNationN" }
      ]
    },
    {
      "name": "AshxGems",
      "title": "14,500",
      "imageSrc": bgkol53,
      "socialLinks": [
        { "platform": "twitter", "url": "https://x.com/ashxgemsss" }
      ]
    },
    {
      "name": "Moon Walker",
      "title": "14,190",
      "imageSrc": bgkol54,
      "socialLinks": [
        { "platform": "telegram", "url": "https://t.me/tehMoonwalkeRs" }
      ]
    },
    {
      "name": "Fomo Catcher",
      "title": "20,500",
      "imageSrc": bgkol52,
      "socialLinks": [
        { "platform": "twitter", "url": "https://x.com/FomoCatchers" }
      ]
    },
    {
      "name": "Crypto Legend",
      "title": "18,000",
      "imageSrc": bgkol70,
      "socialLinks": [
        { "platform": "twitter", "url": "https://twitter.com/cryptolegend81" }
      ]
    },
    {
      "name": "Ajith",
      "title": "29,900",
      "imageSrc": bgkol59,
      "socialLinks": [
        { "platform": "youtube", "url": "https://www.youtube.com/channel/UCxPH_9TZEWJwCueIaT1pd3A" }
      ]
    },
    {
      "name": "Kadir",
      "title": "27,700",
      "imageSrc": bgkol61,
      "socialLinks": [
        { "platform": "twitter", "url": "https://x.com/Kadiralar20" }
      ]
    },
    {
      "name": "Altcoin Alerts",
      "title": "27,500",
      "imageSrc": bgkol69,
      "socialLinks": [
        { "platform": "twitter", "url": "https://twitter.com/Altcoin_Alerts" }
      ]
    },
    {
      "name": "Tech Fanatic",
      "title": "27,000",
      "imageSrc": bgkol64,
      "socialLinks": [
        { "platform": "youtube", "url": "https://www.youtube.com/c/Techfanaticofficial" }
      ]
    },
    {
      "name": "ᴋɪʟɪÇᴀsʟᴀɴ",
      "title": "19,000",
      "imageSrc": bgkol77,
      "socialLinks": [
        { "platform": "twitter", "url": "https://x.com/Kilicaslan_tr" }
      ]
    },
    {
      "name": "Crypto Viet Girl",
      "title": "13,000",
      "imageSrc": bgkol79,
      "socialLinks": [
        { "platform": "twitter", "url": "https://x.com/crptvietgirl" }
      ]
    },
    {
      "name": "Roger",
      "title": "15,000",
      "imageSrc": bgkol83,
      "socialLinks": [
        { "platform": "twitter", "url": "https://x.com/RogerCrypto_" }
      ]
    },
    {
      "name": "Show Maker",
      "title": "97,000",
      "imageSrc": bgkol80,
      "socialLinks": [
        { "platform": "twitter", "url": "https://x.com/0xshowmaker" }
      ]
    },
    {
      "name": "Crypto Verse",
      "title": "85,000",
      "imageSrc": bgkol81,
      "socialLinks": [
        { "platform": "twitter", "url": "https://x.com/CryptoXVerse" }
      ]
    },
    {
      "name": "Kevena",
      "title": "240,000",
      "imageSrc": bgkol82,
      "socialLinks": [
        { "platform": "twitter", "url": "https://x.com/DevveSquad100x" }
      ]
    },
    {
      "name": "Phoenix",
      "title": "25,000",
      "imageSrc": bgkol65,
      "socialLinks": [
        { "platform": "twitter", "url": "https://twitter.com/pnxgrp" }
      ]
    },
    {
      "name": "Lambo Chad",
      "title": "18,000",
      "imageSrc": bgkol76,
      "socialLinks": [
        { "platform": "twitter", "url": "https://x.com/LamboChads" }
      ]
    },
    {
      "name": "Mystery of Crypto",
      "title": "350,000",
      "imageSrc": bgkol86,
      "socialLinks": [
        { "platform": "twitter", "url": "https://x.com/Mysteryofcrypt" }
      ]
    },
    {
      "name": "Samurai Crypto",
      "title": "105,000",
      "imageSrc": bgkol87,
      "socialLinks": [
        { "platform": "twitter", "url": "https://twitter.com/samurai_cz" }
      ]
    },
    {
      "name": "Chain Broker",
      "title": "22,500",
      "imageSrc": bgkol49,
      "socialLinks": [
        { "platform": "twitter", "url": "https://twitter.com/chain_broker" }
      ]
    },
    {
      "name": "Swiat Crypto",
      "title": "22,600",
      "imageSrc": bgkol48,
      "socialLinks": [
        { "platform": "twitter", "url": "https://twitter.com/SwiatKrypto" }
      ]
    },
    {
      "name": "Crypto Kuii",
      "title": "22,100",
      "imageSrc": bgkol50,
      "socialLinks": [
        { "platform": "twitter", "url": "https://twitter.com/CryptoKuiil" }
      ]
    },
    {
      "name": "Prestito",
      "title": "22,100",
      "imageSrc": bgkol51,
      "socialLinks": [
        { "platform": "youtube", "url": "https://www.youtube.com/@PrestitoCrypto" }
      ]
    }
  ]

  export const globallist = [
    {
      "name": "Luke Belmar",
      "title": 5500000,
      "socialLinks": [
        { "platform": "twitter", "url": "https://twitter.com/lukebelmar" }
      ]
    },
    {
      "name": "Brian Jung",
      "title": 1750000,
      "socialLinks": [
        { "platform": "youtube", "url": "https://www.youtube.com/@Jungernaut" }
      ]
    },
    {
      "name": "Crypto Banter",
      "title": 1500000,
      "socialLinks": [
        { "platform": "twitter", "url": "https://twitter.com/crypto_banter" }
      ]
    },
    {
      "name": "Lark Davis",
      "title": 1100000,
      "socialLinks": [
        { "platform": "twitter", "url": "https://twitter.com/TheCryptoLark" }
      ]
    },
    {
      "name": "Brian Evan",
      "title": 1100000,
      "socialLinks": [
        { "platform": "twitter", "url": "https://twitter.com/BrianDEvans" }
      ]
    },
    {
      "name": "The Wolf of All Streets",
      "title": 951000,
      "socialLinks": [
        { "platform": "twitter", "url": "https://twitter.com/scottmelker" }
      ]
    },
    {
      "name": "Pentoshi",
      "title": 776000,
      "socialLinks": [
        { "platform": "twitter", "url": "https://twitter.com/Pentosh1" }
      ]
    },
    {
      "name": "Dog",
      "title": 773000,
      "socialLinks": [
        { "platform": "twitter", "url": "https://twitter.com/TheCryptoDog" }
      ]
    },
    {
      "name": "WIZZ",
      "title": 671000,
      "socialLinks": [
        { "platform": "twitter", "url": "https://twitter.com/CryptoWizardd" }
      ]
    },
    {
      "name": "CryptoGodJohn",
      "title": 652000,
      "socialLinks": [
        { "platform": "twitter", "url": "https://twitter.com/CryptoGodJohn" }
      ]
    },
    {
      "name": "That Martini Guy",
      "title": 550000,
      "socialLinks": [
        { "platform": "twitter", "url": "https://twitter.com/MartiniGuyYT" }
      ]
    },
    {
      "name": "MacnBTC",
      "title": 535000,
      "socialLinks": [
        { "platform": "twitter", "url": "https://twitter.com/MacnBTC" }
      ]
    },
    {
      "name": "Wise Advice",
      "title": 532000,
      "socialLinks": [
        { "platform": "youtube", "url": "https://www.youtube.com/@WISEADVICEE" }
      ]
    },
    {
      "name": "Lady Crypto",
      "title": 521000,
      "socialLinks": [
        { "platform": "twitter", "url": "https://twitter.com/LadyofCrypto1" }
      ]
    },
    {
      "name": "Miles Deutsacher",
      "title": 487000,
      "socialLinks": [
        { "platform": "twitter", "url": "https://twitter.com/milesdeutscher" }
      ]
    },
    {
      "name": "Real Dreylo",
      "title": 417800,
      "socialLinks": [
        { "platform": "twitter", "url": "https://x.com/RealDreylo" }
      ]
    },
    {
      "name": "Bull Bnb",
      "title": 400000,
      "socialLinks": [
        { "platform": "twitter", "url": "https://x.com/bull_bnb" }
      ]
    },
    {
      "name": "Sjuul",
      "title": 390000,
      "socialLinks": [
        { "platform": "twitter", "url": "https://twitter.com/AltCryptoGems" }
      ]
    },
    {
      "name": "Zeneca",
      "title": 377000,
      "socialLinks": [
        { "platform": "twitter", "url": "https://twitter.com/Zeneca" }
      ]
    },
    {
      "name": "Coin Muhendisi",
      "title": 373000,
      "socialLinks": [
        { "platform": "twitter", "url": "https://twitter.com/coinmuhendisim" }
      ]
    },
    {
      "name": "Wendy O",
      "title": 370000,
      "socialLinks": [
        { "platform": "twitter", "url": "https://twitter.com/CryptoWendyO" }
      ]
    },
    {
      "name": "Boxmining",
      "title": 346000,
      "socialLinks": [
        { "platform": "twitter", "url": "https://twitter.com/boxmining" }
      ]
    },
    {
      "name": "VirtualBacon",
      "title": 319000,
      "socialLinks": [
        { "platform": "twitter", "url": "https://twitter.com/VirtualBacon0x" }
      ]
    },
    {
      "name": "Ansem",
      "title": 316000,
      "socialLinks": [
        { "platform": "twitter", "url": "https://twitter.com/blknoiz06" }
      ]
    },
    {
      "name": "Michael Wrubel",
      "title": 311000,
      "socialLinks": [
        { "platform": "youtube", "url": "https://www.youtube.com/c/MichaelWrubel" }
      ]
    },
    {
      "name": "Iced Knife",
      "title": 306500,
      "socialLinks": [
        { "platform": "twitter", "url": "https://twitter.com/IcedKnife" }
      ]
    },
    {
      "name": "Shahh",
      "title": 301000,
      "socialLinks": [
        { "platform": "twitter", "url": "https://twitter.com/shahh" }
      ]
    },
    {
      "name": "Suppoman",
      "title": 300000,
      "socialLinks": [
        { "platform": "twitter", "url": "https://twitter.com/MichaelSuppo" }
      ]
    },
    {
      "name": "CryptoJack",
      "title": 298000,
      "socialLinks": [
        { "platform": "twitter", "url": "https://twitter.com/cryptojack" }
      ]
    },
    {
      "name": "Mi Financial Friend",
      "title": 287000,
      "socialLinks": [
        { "platform": "youtube", "url": "https://www.youtube.com/@MyFinancialFriend" }
      ]
    },
    {
      "name": "Sheldon Evans",
      "title": 279000,
      "socialLinks": [
        { "platform": "twitter", "url": "https://twitter.com/SheldonEvans" }
      ]
    },
    {
      "name": "Hugo Botto",
      "title": 273100,
      "socialLinks": [
        { "platform": "twitter", "url": "https://twitter.com/healthy_pockets" }
      ]
    },
    {
      "name": "Arreat",
      "title": 270000,
      "socialLinks": [
        { "platform": "twitter", "url": "https://twitter.com/thearreatsummit" }
      ]
    },
    {
      "name": "Justin Wu",
      "title": 263000,
      "socialLinks": [
        { "platform": "twitter", "url": "https://twitter.com/hackapreneur" }
      ]
    },
    {
      "name": "Ardizor",
      "title": 258000,
      "socialLinks": [
        { "platform": "twitter", "url": "https://twitter.com/ardizor" }
      ]
    },
    {
      "name": "Sumit Kapoor",
      "title": 255000,
      "socialLinks": [
        { "platform": "twitter", "url": "https://twitter.com/wiseadvicesumit" }
      ]
    },
    {
      "name": "CryptosRUs",
      "title": 238000,
      "socialLinks": [
        { "platform": "twitter", "url": "https://twitter.com/CryptosR_Us" }
      ]
    },
    {
      "name": "Gainzy",
      "title": 234000,
      "socialLinks": [
        { "platform": "twitter", "url": "https://twitter.com/gainzy222" }
      ]
    },
    {
      "name": "Mete",
      "title": 217000,
      "socialLinks": [
        { "platform": "twitter", "url": "https://twitter.com/kriptomete_" }
      ]
    },
    {
      "name": "Sherpa",
      "title": 212000,
      "socialLinks": [
        { "platform": "twitter", "url": "https://twitter.com/AltcoinSherpa" }
      ]
    },
    {
      "name": "Alan Rogers",
      "title": 209000,
      "socialLinks": [
        { "platform": "twitter", "url": "https://twitter.com/alanrog3" }
      ]
    },
    {
      "name": "Mando CT",
      "title": 204000,
      "socialLinks": [
        { "platform": "twitter", "url": "https://twitter.com/XMaximist" }
      ]
    },
    {
      "name": "Eric Cryptoman",
      "title": 200000,
      "socialLinks": [
        { "platform": "twitter", "url": "https://twitter.com/EricCryptoman" }
      ]
    },
    {
      "name": "Rager",
      "title": 199000,
      "socialLinks": [
        { "platform": "twitter", "url": "https://twitter.com/Rager" }
      ]
    },
    {
      "name": "Kmanu",
      "title": 192300,
      "socialLinks": [
        { "platform": "twitter", "url": "https://twitter.com/KmanuS88" }
      ]
    },
    {
      "name": "Kyle Chasse",
      "title": 191000,
      "socialLinks": [
        { "platform": "twitter", "url": "https://twitter.com/kyle_chasse" }
      ]
    },
    {
      "name": "Devchart",
      "title": 188000,
      "socialLinks": [
        { "platform": "twitter", "url": "https://twitter.com/devchart" }
      ]
    },
    {
      "name": "Dave Crypto",
      "title": 187700,
      "socialLinks": [
        { "platform": "twitter", "url": "https://www.x.com/Davecrypto91" }
      ]
    },
    {
      "name": "thebyzantinegeneral",
      "title": 186100,
      "socialLinks": [
        { "platform": "twitter", "url": "https://twitter.com/ByzGeneral" }
      ]
    },
    {
      "name": "Veli Mutlu",
      "title": 185000,
      "socialLinks": [
        { "platform": "twitter", "url": "https://twitter.com/vemutlu" }
      ]
    },
    {
      "name": "Dubzy",
      "title": 182800,
      "socialLinks": [
        { "platform": "telegram", "url": "https://t.me/dubzyxbt" }
      ]
    },
    {
      "name": "Kapo",
      "title": 176000,
      "socialLinks": [
        { "platform": "twitter", "url": "https://twitter.com/KAPOTHEGOAT01" }
      ]
    },
    {
      "name": "Ralvero",
      "title": 166000,
      "socialLinks": [
        { "platform": "twitter", "url": "https://twitter.com/ralvero" }
      ]
    },
    {
      "name": "Paul Barron",
      "title": 165000,
      "socialLinks": [
        { "platform": "twitter", "url": "https://twitter.com/paulbarron" }
      ]
    },
    {
      "name": "Jesus Martinez",
      "title": 160000,
      "socialLinks": [
        { "platform": "twitter", "url": "https://twitter.com/0xJesusMartinez" }
      ]
    },
    {
      "name": "Nekoz",
      "title": 150000,
      "socialLinks": [
        { "platform": "twitter", "url": "https://twitter.com/nekoztek" }
      ]
    },
    {
      "name": "Crypto Busy",
      "title": 145000,
      "socialLinks": [
        { "platform": "twitter", "url": "https://twitter.com/CryptoBusy" }
      ]
    },
    {
      "name": "Michael Suppo",
      "title": 142400,
      "socialLinks": [
        { "platform": "telegram", "url": "https://t.me/suppoman_official" }
      ]
    },
    {
      "name": "The House of crypto",
      "title": 133000,
      "socialLinks": [
        { "platform": "youtube", "url": "https://www.youtube.com/@TheHouseOfCrypto" }
      ]
    },
    {
      "name": "MacroCRG",
      "title": 130000,
      "socialLinks": [
        { "platform": "twitter", "url": "https://twitter.com/macrocrg" }
      ]
    },
    {
      "name": "Oversdose",
      "title": 125800,
      "socialLinks": [
        { "platform": "twitter", "url": "https://twitter.com/Overdose_AI" }
      ]
    },
    {
      "name": "Reflection",
      "title": 125400,
      "socialLinks": [
        { "platform": "twitter", "url": "https://twitter.com/0xReflection" }
      ]
    },
    {
      "name": "Your friend Andy",
      "title": 122000,
      "socialLinks": [
        { "platform": "youtube", "url": "https://www.youtube.com/c/YourFriendAndy" }
      ]
    },
    {
      "name": "Dogen",
      "title": 119000,
      "socialLinks": [
        { "platform": "twitter", "url": "https://twitter.com/realdogen" }
      ]
    },
    {
      "name": "Kmoney",
      "title": 118000,
      "socialLinks": [
        { "platform": "twitter", "url": "https://twitter.com/kmoney_69" }
      ]
    },
    {
      "name": "CryptoNauts",
      "title": 110000,
      "socialLinks": [
        { "platform": "youtube", "url": "https://www.youtube.com/@cryptonauts" }
      ]
    },
    {
      "name": "5StarIDO",
      "title": 107100,
      "socialLinks": [
        { "platform": "twitter", "url": "https://x.com/5StarIDO" }
      ]
    },
    {
      "name": "NFTBoi",
      "title": 103000,
      "socialLinks": [
        { "platform": "twitter", "url": "https://x.com/nftboi_" }
      ]
    },
    {
      "name": "Axel Bitblaze",
      "title": 103000,
      "socialLinks": [
        { "platform": "telegram", "url": "https://t.me/axelbitblaze" }
      ]
    },
    {
      "name": "Thgeo Trader",
      "title": 94000,
      "socialLinks": [
        { "platform": "twitter", "url": "https://twitter.com/theo_crypto99" }
      ]
    },
    {
      "name": "Cameron Fous",
      "title": 94000,
      "socialLinks": [
        { "platform": "twitter", "url": "https://twitter.com/Cameronfous" }
      ]
    },
    {
      "name": "Hustlepedia",
      "title": 78900,
      "socialLinks": [
        { "platform": "twitter", "url": "https://twitter.com/0xHustlepedia" }
      ]
    },
    {
      "name": "Joey Parys",
      "title": 78000,
      "socialLinks": [
        { "platform": "twitter", "url": "https://x.com/JoeParys" }
      ]
    },
    {
      "name": "Alpha BTC",
      "title": 76100,
      "socialLinks": [
        { "platform": "twitter", "url": "https://twitter.com/mark_cullen" }
      ]
    },
    {
      "name": "Batman",
      "title": 74000,
      "socialLinks": [
        { "platform": "twitter", "url": "https://twitter.com/CryptosBatman" }
      ]
    },
    {
      "name": "JohnnyZCash",
      "title": 70000,
      "socialLinks": [
        { "platform": "twitter", "url": "https://twitter.com/JohnnyZcash" }
      ]
    },
    {
      "name": "Erik Stevens",
      "title": 56000,
      "socialLinks": [
        { "platform": "twitter", "url": "https://twitter.com/IHunt100xGems" }
      ]
    },
    {
      "name": "CTM",
      "title": 56000,
      "socialLinks": [
        { "platform": "twitter", "url": "https://twitter.com/CryptoTalkMan" }
      ]
    },
    {
      "name": "Sugar",
      "title": 55400,
      "socialLinks": [
        { "platform": "twitter", "url": "https://twitter.com/suganarium" }
      ]
    },
    {
      "name": "Definaut",
      "title": 52000,
      "socialLinks": [
        { "platform": "twitter", "url": "https://twitter.com/DeFiDiscussion" }
      ]
    },
    {
      "name": "Maven Capital",
      "title": 47000,
      "socialLinks": [
        { "platform": "twitter", "url": "https://twitter.com/murtazasats" }
      ]
    },
    {
      "name": "Conor Kenny",
      "title": 40000,
      "socialLinks": [
        { "platform": "twitter", "url": "https://twitter.com/conorfkenny" }
      ]
    },
    {
      "name": "Dylan K",
      "title": 39000,
      "socialLinks": [
        { "platform": "twitter", "url": "https://twitter.com/MightyDylanK" }
      ]
    },
    {
      "name": "Uncle Eddie",
      "title": 35000,
      "socialLinks": [
        { "platform": "twitter", "url": "https://twitter.com/MediaGiraffes" }
      ]
    },
    {
      "name": "LordDurden",
      "title": 30000,
      "socialLinks": [
        { "platform": "twitter", "url": "https://twitter.com/LordDurden" }
      ]
    },
    {
      "name": "Arap Faik",
      "title": 26100,
      "socialLinks": [
        { "platform": "twitter", "url": "https://x.com/FaikbinArap" }
      ]
    },
    {
      "name": "Brian Rose",
      "title": 1400000,
      "socialLinks": [
        { "platform": "twitter", "url": "https://x.com/LondonRealTV" }
      ]
    },
  ]
  
